.beneficiaryImage {
  max-width: 100px;
}

.beneficiaryLink {
  align-items: center;
  background: #485d8b;
  border-radius: 100px;
  display: flex;
  fill: #ffffff;
  justify-content: center;
  height: 25px;
  transition: background 200ms ease;
  width: 25px;

  &:hover {
    background: #223d8b;
    transition: background 200ms ease;
  }
}