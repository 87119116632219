@import '../../../styles/variables.scss';

.monthlyIncomeContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.label {
  color: #ffffff;
  font-size: 13px;
  margin-bottom: 10px;
}

.inputContainer {
  margin-bottom: 35px;
  position: relative;
  width: 100%;
}

.currency {
  color: #ffffff;
  height: 45px;
  font-size: 38px;
  font-weight: 300;
  line-height: 45px;
  left: 3px;
  position: absolute;
  top: 0;
}

.input {
  background: transparent;
  border: 0;
  border-bottom: 2px solid #e4e4e4;
  color: #ffffff;
  height: 48px;
  font-family: $default-font;
  font-size: 39px;
  font-weight: 300;
  line-height: 48px;
  padding-left: 30px;
  transition: background 200ms ease;
  width: 100%;

  &.inputEditable {
    background: rgba(255, 255, 255, 0.07);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    outline: none;
    transition: background 200ms ease;
  }
}

.editIcon {
  cursor: pointer;
  fill: #ffffff;
  transition: fill 200ms ease;
  position: absolute;
  right: 3px;
  top: 12px;

  &:hover {
    fill: #a6a6a6;
    transition: fill 200ms ease;
  }
}