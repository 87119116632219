@import "../../../../../../styles/variables";

.filters {
  @include rounded;
  @include withBorder;
  background-color: $white;
  padding: 6px;
  margin-bottom: 16px;
}

.close {
  float: right;
  cursor: pointer;

  svg {
    fill: $primary-color;
  }
}

.header {
  padding: 6px;
}

.tag {
  @include rounded;
  display: inline-block;
  font-size: 11.1px;
  color: $white;
  background-color: $primary-color;
  padding: 2px 10px;

  &.highlightTitle {
    background-color: #252525;
    color: #ffffff;
  }
}

.body {
  padding: 6px;
}

.row {
  display: flex;
}

.byDate {
  font-size: 11.6px;
  color: #23426e;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 20px;
  line-height: 30px;
}

.group {
  flex: 1;
  padding: 0 5px;

  label {
    font-size: 12.7px;
    color: #474747;
    font-weight: 600;
    padding-right: 10px;
    line-height: 30px;
  }

  & :global(.ui.fluid.input) {
    flex: 1;
    margin-bottom: 3px;
  }

  input {
    font-size: 13px;
    border: 0;
    border-radius: 2px;
    font-family: $default-font;
    color: $greyish-brown;
    height: 30px;
    line-height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}

.helpBlock {
  text-align: right;
  font-size: 10.6px;
  color: $font-grey;
}

.control {
  display: flex;
}

.submit {
  margin-left: auto;
}

.button {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    height: 18px;
  }

  &:global(.ui.primary.button) {
    min-height: 30px !important;
    height: 30px !important;
  }
}

.footer {
  padding: 6px;
  font-size: 10.6px;
  line-height: 1.2;
  color: #667995;
  font-weight: 500;
}
