@import "../../styles/variables";

.table {
  background-color: transparent;
  border: 0;

  thead {
    background-color: $primary-color;
  }

  th {
    background-color: $hover-grey;
    color: $font-grey;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    padding: 3px 10px;
    text-align: left;
  }

  td {
    font-size: 12.7px;
    color: $font-grey;
    background-color: transparent;
    padding: 10px;
    min-height: 55px;
    vertical-align: top;
  }

  tbody tr + tr td {
    border-top: solid 1px $hover-grey;
  }

  .tableAmount {
    font-size: 13.9px;
    font-weight: bold;
    color: $black-two;
  }

  .tableDues {
    color: $black-two;
    font-size: 13.9px;
    font-weight: bold;
    text-align: center;
  }

  .tableDuesTitle {
    text-align: center;
  }

  .tableNegativeAmount {
    font-size: 13.9px;
    font-weight: bold;
    color: $dusty-orange;
  }

  .tdDownloadIcon {
    text-align: center;

    a {
      fill: $dusk-blue;
      display: block;
      transition: fill 200ms ease;

      &:hover {
        fill: $blueberry;
        transition: fill 200ms ease;
      }
    }
  }

  .noLineBreak {
    white-space: nowrap;
  }

  .noResults {
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
}

.actionableTr {
  cursor: pointer;

  &:hover {
    td {
      background-color: rgba(0, 0, 0, .025);
    }
  }
}
