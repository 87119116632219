@import "../../styles/animations/fadein";

.stepContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.stepTopContainer {
  margin-left: auto;
  margin-right: auto;
}

.title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  padding-bottom: 15px;
  padding-top: 17px;
}

.desc {
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 30px;
}

.inputsContainer {
  display: flex;
  justify-content: space-between;
}

.stepSecondInputs {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.stepButtonContainer {
  display: flex;
  justify-content: space-between;
}

.button {
  border-radius: 5px;
  color: #fefefe !important;
  font-size: 12px;
  font-weight: bold;
  height: 32px !important;
  justify-content: center !important;
  text-align: center !important;
  text-transform: uppercase;
  transition: background 200ms ease;
  width: 173px !important;
}

.cancelButton {
  @extend .button;

  background: transparent !important;
  border: 1px solid #aaaaaa !important;

  &:hover {
    background: darken(#23426f, 20) !important;
    transition: background 200ms ease;
  }
}

.continueButton {
  @extend .button;

  background: #23426f !important;

  &:hover {
    background: darken(#23426f, 10) !important;
    transition: background 200ms ease;
  }
}

.fadeIn {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
}

.stepOneAnimated {
  @extend .fadeIn;
  animation-delay: 1000ms;
}