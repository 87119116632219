@import "../../../styles/variables.scss";

.filtersContainer {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  flex: 2;

  label {
    margin-right: 5px;
  }

  :global(.text) {
    font-size: 12px !important;
  }
}

.filtersContainerTop {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-bottom: 10px;
}

.filtersContainerTopRanking {
  :global(.ui.selection) {
    margin-right: 0 !important;
  }
}

.searchFilterSpacer {
  width: 30%;
}

.selectFilterContainer {
  :global {
    .ui.selection {
      .search {
        border: none !important;
        color: $font-grey !important;
        font-size: 12px !important;
        height: 26px;
        line-height: 26px;
        padding: 0;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .ui.selection.dropdown {
      display: flex;
      flex: 1;
      padding-left: 10px !important;
      background: white !important;
      border: none !important;
      margin-right: 10px;
      align-items: center;
      min-height: 26px;
      line-height: 26px;
      padding-bottom: 0;
      padding-top: 0;
      width: 100%;

      .default {
        font-size: 14px;
      }

      .menu {
        border: none !important;
        border-radius: 0 !important;
      }
    }

    .icon {
      font-size: 9px !important;
      padding: 0 !important;
      position: absolute;
      right: 0 !important;
      top: 0 !important;
    }

    .text {
      color: $font-grey !important;
      font-size: 12px;
      position: relative;
    }

    .ui.selection.dropdown .menu > .item {
      height: 30px !important;
      line-height: 30px !important;
      padding: 0 !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
      font-size: 0;

      &.text {
        font-size: 13px;
      }
    }
  }
}

.filtersContainerBottom {
  display: flex;
  margin-left: auto;
  position: relative;
  width: 100%;
}

.sortIcon {
  align-items: center;
  background: $primary-color;
  cursor: pointer;
  display: flex;
  height: 29px;
  fill: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  transition: background 200ms ease;
  width: 29px;

  &:hover {
    background: darken(#a0bbd9, 10);
    transition: background 200ms ease;
  }
}
