@import "../../styles/variables.scss";

.item {
  @include withBorder;
  @include rounded;
  background: white;
  cursor: pointer;
  padding: 20px 10px;
  position: relative;
  transition: background 200ms ease;

  &:hover {
    background: $hover-grey;
    transition: background 200ms ease;
  }

  &.hidden {
    opacity: 0;
  }
}

.graphPosition {
  border-radius: 100px;
  color: #ffffff;
  font-size: 12px;
  width: 22px;
  z-index: 6;
  height: 22px;
  line-height: 22px;
  margin: 0 auto;
  transform: translateY(-100%);
  text-align: center;
}

.itemPercent {
  border-top: 1px solid #ffffff;
  color: $font-grey;
  display: flex;
  text-transform: uppercase;
  justify-content: space-between;
  font-size: 10px;
  font-weight: 500;
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.itemImg {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto 10px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.itemBody {
  text-align: center;
}

.itemName {
  color: $font-grey;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 13px;
}

.itemPosition {
  color: $font-grey;
  font-size: 10px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.percentage {
  color: $primary-dark;
  font-size: 15px;
  font-weight: bold;
}

.percentageGoal {
  color: $primary-color;
  font-size: 15px;
  font-weight: bold;
}

.percentageLow {
  color: $secondary;
}

.productName {
  flex: 1;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  padding-right: 3px;
}
