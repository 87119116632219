@import "../../styles/variables.scss";

.wrapper {
  display: flex;
  justify-content: space-between;
}

.wrapperError {
  border-color: #eb1a3c;
}

.item {
  margin: 0;
  padding: 0;
  cursor: pointer;

  input {
    display: none;
  }

}

.itemBody {
  display: flex;
  background-color: $hover-grey;
  display: flex;
  padding: 10px;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  transition: all .2s ease-in-out;

  .itemActive & {
    background-color: $primary-color;
  }

  .item:hover:not(.itemActive) & {
    background: transparentize($font-grey, 0.7);

    svg {
      fill: #ffffff;
    }
  }
}

.itemIcon {
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: $font-grey;
    transition: all .2s ease-in-out;
  }

  .item:hover:not(.itemActive) & {
    svg {
      fill: #ffffff;
    }
  }

  .itemActive & {

    svg {
      fill: #ffffff;
    }
  }
}

.itemLabel {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $font-grey;
  transition: all .2s ease-in-out;

  .item:hover:not(.itemActive) & {
    color: $white;
  }

  .itemActive & {
    color: #ffffff;
  }
}
