:global {
  .error.field {
    label {
      color: red;
    }

    input,
    .dropdown {
      border-color: red !important;
    }
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
}