@import "../../styles/animations/fadein";
@import "../../styles/variables.scss";

.optionSelection {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    border-bottom: 1px solid $white;
    color: $font-grey;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    height: 30px;
    line-height: 30px;
    transition: color 200ms ease;

    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 500ms;
    opacity: 0;
    opacity: 1 \9; /*just in case ie*/
    animation-delay: 0.3s;

    &:hover {
      color: $primary-dark;
      transition: color 200ms ease;
    }
  }
}
