@import "../../../styles/variables";

.miniCustomerFile{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 250px;
  background-color: $white;
  transition: all .5s ease-in-out;
  z-index: 3;
  overflow: auto;
}

.header {
  padding: 10px 5px;
  margin-bottom: auto;
}

.close, .fileLink {
  position: absolute;
  top: 10px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  opacity: 0.83;
  background-color: $primary-color;
  transition: opacity .3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  svg {
    fill: $white;
    width: 20px;
    height: 20px;
  }
}

.img {
  display: block;
  position: relative;
  width: 185px;
  height: 185px;
  background: $hover-grey no-repeat center center;
  background-size: cover;
  border: $primary-color solid 5px;
  margin: auto;
  border-radius: 50%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.fileLink {
  right: 55px;
}

.body {
  //flex: 1;
  overflow: auto;
  padding: 10px;
}

.dataSection {
}

.name {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: bold;
  text-align: center;
  line-height: 1.22;
  color: $font-grey;
  margin-bottom: 24px;
}