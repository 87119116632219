@import "../../styles/variables";

.pageSegment {
  background: #ffffff;
  min-height: 180px;
  padding: 30px 40px;
  position: relative;
  @include withBorder;
  transition: all 0.4s ease;

  &.smallerPadding {
    padding: 15px 20px;
  }

  &.colorized {
    background: #dde3ee;
  }

  &.rounderBorder {
    border-radius: 15px;
  }

  &.noRounderBorderBottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.pageSegmentMargin {
  margin-top: 12px;
}

.pageTitle {
  color: $primary-color;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 30px 0;

  &:last-child {
    margin-bottom: 30px;
  }
}

.pageWrapper {
  min-height: 100%;
  padding: 4rem;
  position: relative;

  &.smallPadding {
    padding: 2.5rem;
  }

  &.customers {
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.pageListTitle {
  color: $font-grey;
  font-size: 16px;
}

.pageFormButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.pageFormErrors {
  background: #fcfcfc;
  border: 1px solid #f43131;
  border-radius: 5px;
  color: #f43131;
  margin: auto;
  margin-bottom: 30px;
  width: 70%;
  padding: 20px;
}
