@import "../../styles/animations/fadein";
@import "../../styles/variables.scss";

.dropdownSelectedOption {
  color: #ffffff;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  height: 25px;
  line-height: 25px;
  margin-top: 10px;

  @extend .fadeIn;

  .dropdownSelectedOptionNumber {
    background: $hover-grey;
    color: $font-grey;
    border-radius: 50%;
    text-align: center;
    width: 30px;
  }

  .dropdownSelectedOptionText {
    background: $hover-grey;
    color: $font-grey;
    border-radius: 15px;
    margin-left: 5px;
    padding-left: 10px;
    width: 100%;
  }
}

.optionArrowBottom {
  fill: #ffffff;
  height: 20px;
  margin-bottom: 5px;
  margin-top: 5px;

  @extend .fadeIn;
}

.fadeIn {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
}
