@import "../../../styles/animations/fadein";

.sidebarUpdateDateContainer {
  cursor: pointer;

  &:hover {
    .popover {
      animation: fadeIn ease-in 1;
      animation-fill-mode: forwards;
      animation-duration: 500ms;
      opacity: 0;
      opacity: 1 \9; /*just in case ie*/
      display: flex;
    }
  }
}

.popover {
  align-items: center;
  background: transparentize(#233f6b, 0.04);
  border-radius: 3px;
  color: #ffffff;
  display: none;
  font-weight: 600;
  height: 82px;
  justify-content: space-around;
  padding-left: 15px;
  padding-right: 15px;
  left: 0;
  text-transform: uppercase;
  top: -82px;
  position: absolute;
  width: 100%;

  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: transparentize(#233f6b, 0.04);
    border-width: 5px;
    margin-left: -5px;
  }
}

.image {
  height: 50px;
  position: relative;
  width: 50px;
}