.percentageBars {
  bottom: -1px;
  display: flex;
  position: absolute;
  top: -1px;
}

.percentageRemain {
  background: #194375;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.percentageBarContainer {
  align-items: center;
  display: flex;
  flex: 1;
  min-height: 26px;
  z-index: 6;
}

.percentageBar {
  background: #112e50;
  display: inline-block;
  height: 6px;
  position: relative;
  transition: background 200ms ease;
  z-index: 5;

  .percentageBarContainer:hover &,
  &:hover {
    background: #000000;
    transition: background 200ms ease;
  }
}

.percentageUnit {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  height: 100%;
  min-width: 1px;

  &.takeRemainingSpace {
    flex: 1;
  }
}

.percentageBarTotal {
  font-weight: 600;
  font-size: 11px;
  position: absolute;
  top: -20px;
  right: 0;

  &.smallPercentage {
    right: -15px;
  }
}

.popoverText {
  font-size: 12px;
  white-space: nowrap;
}

.popoverValue {
  font-size: 12px;
  font-weight: bold;
}