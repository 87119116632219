@import "../../styles/variables";

.notifications {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  left: 0;
  list-style: none;
  position: fixed;
  top: 41px;
  width: 100%;
  z-index: 10000;
}

.notification {
  box-shadow: 0 5px 10px -5px rgba(89, 89, 89, 0.57);
  color: #ffffff;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
  text-align: center;
  width: 600px;
}

@keyframes bounceNotificationClose {
  0%{ margin-top: 0; }
  50%{ margin-top: -5px; }
  100%{ margin-top: 0; }
}

.notificationClose {
  cursor: pointer;
  fill: #ffffff;
  position: absolute;
  right: 5px;
  top: 5px;

  &:hover {
    animation: bounceNotificationClose 200ms ease;
  }
}

.notificationInfo,
.notificationSuccess,
.notificationWarning,
.notificationError {
  @extend .notification;
}

.notificationInfo {
  background: #2271a5;
}

.notificationSuccess {
  background: #8da3ca;
}

.notificationWarning {
  background: #f4a426;
}

.notificationError {
  background: $error-color;
}