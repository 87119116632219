.literalNameLabel {
  margin-bottom: 15px;
}

.literalName {
  display: block;
  text-transform: uppercase;
}

.typeSelectionRadios {
  display: flex;
  font-weight: 500;
}

.typeSelectionRadio {
  margin-right: 20px;
}