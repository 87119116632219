.header {
  align-items: center;
  background: #1f618b;
  display: flex;
  height: 60px;
  padding-left: 10px;

  img {
    height: 40px;
  }
}