@import "../../../../styles/variables";

.floatingCustomer {
  @include withBorder;
  @include withShadows;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  display: flex;
  align-items: center;
  padding: 16px 20px 18px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all .25s ease-in-out;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.avatar {
  margin-right: auto;
  width: 48px;
  height: 48px;
  box-sizing: content-box;
  background: $hover-grey;

  img {
    width: 48px;
    height: 48px;
  }
}

.name {
  flex: 1;
  color: $font-grey;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.21;
  padding: 0 13px;
}

.goToTop {
  margin: 0 -5px 0 auto;
  width: 34px;
  height: 34px;
  background: $primary-color;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform .25s ease-in-out;

  svg {
    fill: #ffffff;
  }

  &:hover {
    transform: scale(1.1)
  }
}
