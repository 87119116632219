@import '../../../styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  flex-basis: 30%;
  width: 30%;
  flex-wrap: wrap;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  justify-content: space-between;
  align-self: flex-start;
}

.item {
  background: #fff !important;
  text-align: left;
  border-radius: 15px;
  display: flex;
  flex: 1;
  padding: 40px 10px;
  flex-direction: column;
  @include withBorder;
}

.title {
  font-weight: bold;
  margin-bottom: 12px;
}

.itemInfo {
  flex: 1;
  display: flex;
}

.itemCounter {
  flex: 1;
  padding: 0 15px;

  & + & {
    border-left: #fff solid 1px;
  }
}

.itemCounterCount {
  font-size: 40px;
  color: $primary-color;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 5px;
}

.itemCounterTitle {
  font-size: 13px;
  font-weight: 600;
  color: $font-grey;
  line-height: 1.4;
  margin-bottom: 5px;
}
