.selectionButtons {
  display: flex;
  margin-bottom: 15px;
}

.selectionButton {
  align-items: center;
  background: #ffffff;
  border: 1px solid #d6d3d3;
  border-radius: 50px;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 21px;
  margin-right: 10px;
  padding-left: 11px;
  padding-right: 11px;
  transition: background 200ms ease, color 200ms ease;
}

.buttonBullet {
  border-radius: 50px;
  display: block;
  height: 11px;
  margin-right: 8px;
  transition: background 200ms ease;
  width: 11px;
}

.buttonNotSelected {
  color: #b9b9b9;
  transition: color 200ms ease;
}

.selectionButtonsTitle {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}