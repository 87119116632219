@import "../../../styles/variables";

.viewMoreContainer {
  align-items: center;
  background: $search-no-client-bg;
  cursor: pointer;
  display: flex;
  fill: $search-no-client-main;
  justify-content: center;
  height: 35px;
  opacity: 1;
  transition: background 200ms ease;

  &:hover {
    background: darken($search-no-client-bg, 3);
    transition: background 200ms ease;
  }

  &.isClient {
    background: $search-client-bg;
    fill: $search-client-main;

    &:hover {
      background: darken($search-client-bg, 3);
    }
  }
}