.input input {
  border: 0 !important;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.placeholder {
  text-align: center;
  padding: 40px 20px;
  color: #333;
}