@import "../../../styles/variables";

.resultListPlaceholder {
  align-items: center;
  background: $search-client-bg;
  display: flex;
  min-height: 45px;
  margin-bottom: 5px;
  padding-left: 17px;
  padding-right: 17px;
}

.resultListPlaceholderImage {
  background: #dedede;
  border-radius: 50px;
  height: 39px;
  width: 39px;
}