@import "../../../styles/animations/fadein";

.inputLabel {
  color: #ffffff;
  display: block;
  font-size: 13px;
  margin-bottom: 17px;
}

.hasRightText {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 13px;
  justify-content: space-between;

  :global {
    .field {
      flex: 1;
      margin-right: 10px;
    }
  }
}
.stepInput {
  :global {
    .ui.input {
      width: 100%;
    }

    .ui input {
      color: #4b5e73 !important;
      font-size: 13px !important;
      font-weight: 400 !important;
      height: 27px !important;
      line-height: 25px !important;
      min-height: 27px !important;
      padding: 0 !important;
      padding-left: 15px !important;
      width: 100%;
    }

    .ui.selection.dropdown {
      border: 1px solid #e4e4e4 !important;
      height: 27px !important;
      min-height: 27px !important;
      line-height: 25px !important;
      padding: 0 !important;
      padding-left: 15px !important;

      .text {
        color: #4b5e73 !important;
        font-size: 13px !important;
        font-weight: 400 !important;
      }

      .icon {
        padding: 0 !important;
        right: 20px !important;
        top: 15px !important;
      }
    }
  }
}


.fadeIn {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
}

.stepInputAnimated {
  @extend .fadeIn;
  animation-delay: 2000ms;
}
