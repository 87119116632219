.waitingForImage {
  bottom: 0;
  filter: drop-shadow(0px 0px 5px #000);
  fill: #ffffff;
  height: 24px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 24px;
  left: 50%;
  display: flex;
  text-align: center;
  top: 50%;
  align-items: center;
  justify-content: center;
}