@import "../../styles/variables.scss";

.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

.item {
  display: flex;
  align-items: center;
  width: 170px;
  padding: 15px 10px;
  position: relative;
  transition: background-color .3s ease-in-out;
  border-radius: 5px;

  &.disabled {
    opacity: .75;
    cursor: pointer;

    &:hover {
      background-color: $hover-grey;
    }
  }

  &.hidden {
    opacity: 0;
  }
}

.itemImg {
  border-radius: 50%;
  flex-basis: 52px;
  min-width: 52px;
  height: 52px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;

    .disabled & {
      filter: saturate(0);
    }
  }

  .disabled & {
    border-color: transparent;
  }
}

.itemPercent {
  color: $primary-color;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.3;
}

.itemName {
  color: $font-grey;
  font-size: 12px;
  line-height: 1.3;
  font-weight: bold;
  text-transform: capitalize;
}

.arrow {
  fill: $primary-color;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0, -50%);

  .last & {
    display: none;
  }
}
