@import '../../styles/variables.scss';

.dateLabel {
  font-size: 20px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  .dayName {
    display: flex;
    color: $primary-color;
    font-size: 3rem;
    font-weight: bold;
    line-height: 3rem;
    padding-right: 0.5rem;
  }

  .monthYear {
    display: flex;
    color: $font-grey;
    letter-spacing: 1px;
    flex-direction: column;
    font-weight: 300;
    font-size: 1.2rem;
    justify-content: flex-start;
  }

  .monthName {
    display: flex;
    text-transform: uppercase;
  }

  .yearName {
    display: flex;
  }
}
