.modalContent {
  padding-left: 30px;
  padding-right: 30px;
}

.modal {
  &:global(.modal.ui) {
    width: 652px !important;
  }

  &:global(.modal.ui .content),
  &:global(.modal.ui .actions) {
    background-color: #e5eaf3;
    border: 0;
  }

  :global .field textarea {
    border: 0 !important;
  }

  :global .field label {
    color: #224874;
    font-size: 13px;
    font-weight: 600;
    margin-right: 15px;
  }

  :global .ui.input input[type="text"],
  :global .ui.input input[type="date"],
  :global .ui.input input[type="time"] { /*,*/
    border: 1px solid #e4e4e4 !important;
    height: 30px;
  }

  :global .error.field .input input[type="text"] {
    border-color: red !important;
  }
}

.title {
  color: #243e6a;
  font-size: 17px;
  font-weight: bold;
  padding: 20px 0 30px;
  text-transform: uppercase;
  text-align: center;
}

.formRowContainer {
  display: flex;
  justify-content: space-between;

  :global {
    .field {
      align-items: center;
      display: flex;
    }

    .selection.dropdown {
      align-items: center !important;
      border: 1px solid #e4e4e4 !important;
      display: flex !important;
      height: 30px !important;
      min-height: 30px !important;
      padding: 0 !important;
      padding-left: 15px !important;

      .icon {
        top: 6px !important;
      }
    }
  }
}

.fluidFormContainer {
  :global .field,
  :global .field .ui.input {
    flex: 1;
  }
}

.otherFieldsContainer {
  border-top: 1px solid #dadada;
  margin-top: 20px;
  padding-top: 20px;
  width: 100%;

  :global {
    .input {
      margin-bottom: 15px;
      margin-top: 5px;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}