@import "../../../styles/animations/fadein";

.topContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.labelsContainer {
  display: flex;
  flex-wrap: wrap;
}

.labelContainer {
  margin-bottom: 25px;
  width: 121px;
}

.label {
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 5px;
}

.value {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;

  &.bold {
    font-size: 20px;
    font-weight: bold;
  }
}

.fadeIn {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
}

.labelsContainerAnimated {
  @extend .fadeIn;
  animation-delay: 1000ms;
}