@import '../../styles/variables';

.secondaryNavbar {
  height: 100%;
  background: #fff;
  position: relative;
  @include withBorder;
}

.link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  transition: background-color 250ms linear;

  svg {
    fill: $font-grey;
    transition: opacity 250ms linear, fill 250s linear, transform 100ms ease-in-out;
    opacity: .65;
    height: 60px;
  }

  &:not(.active) {
    &:hover, &:focus {
      background-color: rgba(0, 0, 0, .1);

      svg {
        opacity: 1;
        fill: $secondary-navbar;
      }
    }
  }

  &.active {
    border-right: 3px solid $secondary-navbar;

    svg {
      opacity: 1;
      fill: $secondary-navbar !important;
    }
  }

  .activeSubmenu & {
    background-color: $secondary-navbar !important;

    svg {
      fill: $white !important;
      opacity: 1;
    }
  }
}

.navItemTitle {
  @include rounded;
  align-items: center;
  background: $secondary-navbar;
  color: #ffffff;
  display: flex;
  font-size: 10px;
  font-weight: bold;
  height: 16px;
  left: -9999px;
  opacity: 0;
  padding-left: 7px;
  padding-right: 7px;
  position: absolute;
  text-transform: uppercase;
  transition: top 200ms ease, opacity 200ms ease;
  top: 30px;
  white-space: nowrap;
  z-index: 10000;

  .link:hover & {
    left: 17px;
    visibility: visible;
    opacity: 1;
    top: 3px;
    transition: top 200ms ease, opacity 200ms ease;
  }
}

.badge {
  position: absolute;
  top: 50%;
  left: calc(50% + 5px);
  background-image: linear-gradient(to top, #d37420 1%, #eb1a3c);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 11px;
  line-height: 1;
  border-radius: 50%;
  text-align: center;
}

.dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: 99;
  width: 227px;
  background-color: $secondary-navbar;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
  font-family: $font-family-montserrat;

  .activeSubmenu & {
    visibility: visible;
    opacity: 1;
  }
}

.submenuHeader {
  background-color: $primary-dark;
  color: $white;
  font-size: 11.3px;
  text-transform: uppercase;
  padding: 10px 20px;
  margin: 0;
}

.submenuItems {
  padding: 10px 0 20px;
}

.submenuItem {
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 15px;
  transition: background-color .3s ease-in-out;

  &:hover, &:focus {
    background-color: $primary-color;
    color: #ffffff;
  }

  &.active {
    background-color: $primary-color;
    color: $white;
  }
}
