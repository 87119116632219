.modal {
  &:global(.modal.ui) {
    width: 652px !important;
  }
  &:global(.modal.ui .content),
  &:global(.modal.ui .actions) {
    background-color: #e5eaf3;
    border: 0;
  }
  &:global(.modal.ui .content) {
    //min-height: 500px;
  }
  :global .field textarea {
    border: 0 !important;
  }
  :global .ui.form label,
  :global .ui.form .field label {
    color: #224874;
    font-weight: 600;
  }
}

.content {
}

.title {
  color: #243e6a;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  padding: 20px 0 30px;
}

.group {
  display: flex;
  margin-bottom: 15px;

  label {
    min-width: 100px;
    text-align: right;
    padding: 10px 20px 0 0;
  }

  .value {
    flex: 1;
    background-color: #ffffff;
    padding: 10px;
  }
}
.success {
  padding: 20px;
  text-align: center;
}
.successIcon {
  margin: 30px auto 20px;
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  border-color: #4c4c4c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}