@import "../../styles/variables.scss";

.myResultsSegment {
  background: $white;
  @include rounded;
  @include withBorder;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  transition: filter 200ms ease;
}

.myResultsSegmentMargin {
  margin-top: 15px;
  margin-bottom: 15px;
}

.blur {
  filter: blur(3px);
}

.blurOverlay {
  background: rgba(255, 255, 255, 0.1);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
