@import "../../../styles/variables.scss";

.sectionWrapper {
  padding-top: 40px;
  position: relative;
}

.systematicGraphsContainerLoading {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 230px;
  position: relative;
}

.systematicGraphsContainer {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  justify-content: flex-start;
  position: relative;
}

.isBig,
.isSmall {
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 200px;
  transition: background 200ms ease;
  padding-bottom: 20px;
  padding-top: 20px;

  &:hover {
    background: $hover-grey;
    transition: background 200ms ease;
  }
}

.margins {
  margin-left: 1px;
  margin-top: 20px;
}

.isSelectedChildren {
  position: relative;

  &:before {
    bottom: -20px;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 20px;
    margin-left: -20px;
  }
}
