@keyframes slideIn {
  from {
    right: -390px;
  }
  to {
    right: 110px;
  }
}

@keyframes slideOut {
  from {
    right: 110px;
  }

  to {
    right: -390px;
  }
}

.confirmCustomerFromIvrBox {
  animation: slideIn cubic-bezier(0.175, 0.885, 0.32, 1.275) 1;
  animation-delay: 500ms;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  background: #efefef;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 15px 1px;
  display: flex;
  flex-direction: column;
  height: 220px;
  justify-content: space-between;
  right: -390px;
  padding: 35px 15px 15px;
  position: fixed;
  width: 390px;
  z-index: 900;

  &.closing {
    animation: slideOut cubic-bezier(0.6, -0.28, 0.735, 0.045) 1;
    animation-delay: 500ms;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    right: 110px;
  }
}

.text {
  font-weight: bold;
  text-align: center;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
}

.confirmButton,
.cancelButton {
  align-items: center;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 11px;
  font-weight: bold;
  height: 25px;
  justify-content: space-around;
  margin: 0 5px;
  text-align: center;
  text-transform: uppercase;
  transition: all 200ms ease;
  transform: translateZ(0);

  svg {
    fill: #ffffff;
  }
}

.confirmButton {
  background: #3a8000;

  &:hover,
  &:focus {
    background: darken(#3a8000, 10%);
    transition: all 200ms ease;
    transform: scale(1.1);
  }
}

.cancelButton {
  background: #ff0028;

  &:hover,
  &:focus {
    background: darken(#ff0028, 10%);
    transition: all 200ms ease;
    transform: scale(1.1);
  }
}

.phoneSelectionTypeText {
  font-size: 11px;
  margin-top: 10px;
  text-align: center;
}

.phoneSelectionContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.dropdownContainer {
  margin-left: 15px;
  margin-right: 15px;
  width: 120px;
}