@import '../../styles/variables.scss';

:global {
  .rbc-event {
      border-radius: 0px !important;
  }

  .spa-bigcalendar-container {
    .rbc-time-view {
      border: none;
    }

    .rbc-header {
      visibility: hidden;
    }

    .rbc-time-content {
      border-top: none;
    }

    .rbc-timeslot-group {
      border: none;
      border-top: 1px solid #dddddd;
      min-height: 70px;
    }

    .rbc-time-header > .rbc-row > * + * {
      border: none;
    }

    .rbc-label {
      color: $font-grey;
      font-family: $font-family-montserrat;
      font-size: 12px;
      font-weight: 300;
      text-transform: lowercase;
      padding-top: 7px;
    }

    .rbc-day-slot .rbc-time-slot {
      cursor: pointer;
      min-height: 34px;
      transition: background 200ms ease;

      &:hover {
        background: #fafafa;
        transition: background 200ms ease;
      }
    }

    .rbc-day-slot .rbc-event {
      background: transparent;
      border-radius: none;
      border: none;
    }

    .rbc-event-label {
      display: none;
    }

    .rbc-today {
      background-color: #ffffff;
    }

    .rbc-current-time-indicator {
      background-color: #1f5d87;
      z-index: 3 !important;

      &::before {
        background-color: #1f5d87;
      }
    }

    .rbc-selected {
      z-index: 900;
    }
  }
}

.event {
  align-items: flex-start;
  background: white;
  background-opacity: 15%;
  border-left: 7px solid #000;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #4f4f4f;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-weight: bold;
  justify-content: center;
  left: 0;
  height: 100%;
  padding: 10px 20px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: 100%;

  &.isPersonal:not(.isSelected):not(.isGrayed) {
    color: #ffffff !important;
  }

  &.isGrayed {
    color: #bdbdbd !important;
  }

  &.isSelected {
    color: #ffffff !important;
  }
}

.eventDate {
  font-size: 11px;
  flex: none;
  width: auto;
  padding-right: 15px;
}

.eventData {
  color: $font-grey;
  align-items: center;
  text-transform: none;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  flex: 1 1 auto;
  justify-content: flex-start;
  width: 100%;
}

.eventIcons {
  align-items: center;
  display: flex;
  fill: #6a798e;
  justify-content: center;
  right: 2px;
  top: 2px;
  position: absolute;

  .isGrayed & {
    fill: #bdbdbd;
  }

  .isPersonal:not(.isGrayed) & {
    fill: #ffffff !important;
  }

  .isSelected & {
    fill: #ffffff !important;
  }
}

.eventIconStatus {
  align-items: center;
  background: #bdbdbd;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: flex;
  justify-content: center;
  fill: #ffffff;
  height: 20px;
  margin-left: 5px;
  width: 20px;

  .notFinished & {
    background: #eb8497 !important;
  }

  .isSelected & {
    fill: #ffffff !important;
  }
}

.ctaCategoryHeaderLetter {
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 100px;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 22px;
  justify-content: center;
  margin-right: 10px;
  width: 22px;

  .isGrayed & {
    border-color: #bdbdbd !important;
    color: #bdbdbd !important;
  }

  .notFinished & {
    border-color: #eb8497 !important;
    color: #eb8497 !important;
  }

  .isSelected & {
    border-color: #ffffff !important;
    color: #ffffff !important;
  }
}
