@import "../../../../styles/variables";

.calculatingBox
{
  margin-top: 20px;
}

.calculatingBoxContainer {
  margin-bottom: 15px;
}

.rulesBoxContainer {
  background: $filter-bar-top;
  border: 1px solid $input-border;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px;
  padding-top: 60px;
  position: relative;
  z-index: 2;

  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(232, 235, 243, 0);
    border-top-color: #e8ebf3;
    border-width: 15px;
    margin-left: -15px;
  }

  &:before {
    border-color: rgba(178, 178, 178, 0);
    border-top-color: #b2b2b2;
    border-width: 16px;
    margin-left: -16px;
  }
}

.calculatingBoxDelete {
  position: absolute;
  right: 10px;
  top: 10px;
}

.ruleBoxTitle {
  background: $main-color;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  height: 35px;
  line-height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.assignmentBoxContainer {
  background: $filter-bar-top;
  border: 1px solid $input-border;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 15px;
  padding-top: 60px;
  position: relative;
 z-index: 1;

  .ruleBoxTitle {
    border-top-left-radius: 0px;
  }
}

.ruleLogicoConditionContainer {
  display: flex;
  margin-bottom: 15px;
}

.logicConditionSelect {
  border: 1px solid $input-border;
  border-radius: 3px;
  height: 38px;
  line-height: 38px;
  margin-right: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.logicConditionSelectWithScalar {
  @extend .logicConditionSelect;

  margin-right: 0;
}

.booleanConditionSelect {
  @extend .logicConditionSelect;

  margin-left: auto;
  margin-right: 30px;
}

.addOtherButton {
  align-items: center;
  background: $main-color;
  border: 0;
  border-radius: 3px;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  fill: #ffffff;
  height: 38px;
  justify-content: center;
  line-height: 38px;
  margin: 0;
  margin-left: auto;
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;
}

.deleteButton {
  background: transparent;
  border: 0;
  cursor: pointer;
  fill: $primary-color;
  height: 38px;
  line-height: 38px;
  margin: 0;
  margin-left: 10px;
  padding: 0;
}

.scalarField {
  border: 1px solid $input-border;
  border-radius: 3px;
  height: 38px;
  line-height: 38px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.addCalculatingBoxButton {
  align-items: center;
  background: $main-color;
  border: 0;
  border-radius: 3px;
  box-shadow: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  fill: #ffffff;
  height: 38px;
  justify-content: center;
  line-height: 38px;
  margin: 0;
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;
}