@import "../../../../../../styles/variables";

.productAccountDropdown {
  background: $hover-grey;
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-top: 5px;
  padding-top: 15px;
  position: relative;
}

.productAccountDropdownArrow {
  fill: #6e7787;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.productAccountDropdownBox {
  display: none;
}

.productAccountDropdownBoxExpanded {
  display: flex;
  flex-wrap: wrap;
}

.showMore {
  display: block;
  text-align: center;

  svg {
    fill: $primary-color
  }
}
