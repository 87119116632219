@import "../../../styles/variables";

.segment {
  background: #ffffff;
  padding: 30px;
}

.iconsWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
}

.isCoreIcon {
  fill: #7b9bb0;
  margin-left: auto;
}

.isMappedWrapper {
  background: $pinkish-red;
  background-image: linear-gradient(to top, $brownish-orange 1%, $pinkish-red);
  border-radius: 50px;
  margin-right: auto;
  height: 20px;
  width: 20px;
}

.isMapped {
  fill: #ffffff;
}

.listTitleButtonWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}