.specialRateRequestContainer {
  align-items: center;
  display: flex;
  height: 40px;
  margin-top: 15px;
}

.selectBox {
  align-items: center;
  background: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 21px;
  transition: background 200ms ease;
  width: 21px;

  &.active {
    background: #8492ef;
    fill: #ffffff;
    transition: background 200ms ease;
  }
}

.text {
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}

.selection {
  margin-left: 15px;
  width: 65px;

  :global {
    .ui.selection.dropdown .menu > .item {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }
}

.specialRateToggable {
  align-items: center;
  display: flex;
}

.descText {
  color: #ffffff;
  font-size: 11px;
  font-weight: 300;
  line-height: 13px;
  margin-left: 15px;
  width: 260px;
}