@import "../../../src/styles/variables";

.placeholder {
  text-align: center;
  padding: 20px;
}

.showMore {
  display: block;
  text-align: center;

  svg {
    fill: $dark-slate-blue
  }
}

.noResults {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 200px;

}

.minHeight {
  min-height: 320px;
}

.loading {
  align-items: center;
  display: flex;
  min-height: 160px;
}