.sectionSeparatorContainer {
  display: flex;
  height: 107px;
}

.graphConnectors {
  flex: 1;
  position: relative;
}

.sectionSeparatorData {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 156px;
}

.sectionSeparatorTotal {
  font-weight: bold;
  font-size: 12px;
  padding-left: 3px;
  padding-top: 7px;
}

.sectionSeparatorTitle {
  font-weight: bold;
  font-size: 12px;
  padding-bottom: 7px;
  padding-left: 3px;
}