@import '../../styles/variables.scss';

.speechFormControl {
  position: relative;

  & :global(.ui.negative.button) {
    background: transparent !important;

    svg {
      fill: red;
    }
  }
}

.button {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  background: transparent !important;
  align-items: center;
  justify-content: center;
  margin-right: 0 !important;

  svg {
    fill: $primary-color;
  }

  .isScrolled & {
    right: 15px;
  }
}

.speechInput {
  .button {
    top: calc(50% - 15px);
    right: 4px;
  }
}
.speechTextarea .supportSR {
  padding-right: 50px !important;
}
