@import "../../../../../styles/variables.scss";

.amount {
  color: $primary-color;
  font-size: 26.9px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: right;
}

.currency {
  font-size: 17.3px;
}

.body {
  margin-bottom: 10px;
}

.tableCell {
  border: transparent !important;
  border-top: 4px solid rgba(255, 255, 255, .5) !important;
  font-size: 12.7px !important;

  &:first-child {
    padding-left: 5px !important;
  }

  &:last-child {
    padding-right: 5px !important;
  }
}

.itemRow {
  background: transparent;
  cursor: pointer;
  transition: background 300ms ease;
  color: #474747 !important;

  &:hover:not(.hasError) {
    background: #d5ecfb;
  }

  &.itemRowDisabled:not(.hasError) {
    background-color: rgba(255, 255, 255, .3);
    color: #a3a3a3 !important;
  }
}

.productName {
  align-items: center;
  display: flex;
  font-weight: 500;

  svg {
    margin-right: 10px;
  }
}

.productValue {
  font-weight: 600;
}

.hasError {
  background-color: transparentize(#ff7d83, .42);
  cursor: default;

  svg {
    cursor: pointer;
  }
}
