@import "../../../styles/variables.scss";

.myResultsPosition {
  color: $font-grey;
  padding: 20px;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.myResultsPositionTitle {
  align-items: flex-start;
  color: $font-grey;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  flex-direction: column;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 20px;

  svg {
    margin-left: 5px;
  }

  span {
    font-size: 10px;
    font-weight: normal;

  }
}

.myResultsPositionImage {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;

  img {
    background: $hover-grey;
    border-radius: 100px;
    height: 115px;
    width: 115px;
  }
}

.superRankingPositionContainer {
  align-items: center;
  display: flex;
  color: $font-grey;
  justify-content: space-between;
  height: 71px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.superRankingPositionNumber {
  color: $font-grey;
  font-size: 11px;
}

.otherPosition {
  color: $font-grey;
  font-size: 11px;
}

.superRankingPosition {
  color: $primary-color;
  display: inline-block;
  font-size: 34px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
}

.superRankingPositionBoxTitle {
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.superRankingPositionBoxLeft {
  width: 60%;
}

.superRankingPositionBoxRight {
  width: 40%;
}

.superRankingPositionBoxLeft,
.superRankingPositionBoxRight {
  color: $font-grey;
}

.superRankingPositionPercentage {
  color: $primary-color;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  height: 40px;
}

.superRankingPositionPercentageNumber {
  font-size: 34px;

  &.smaller {
    font-size: 26px;
  }
}

.myResultsPositionOtherPosition {
  color: $font-grey;
  align-items: center;
  display: flex;
  font-size: 10px;
  text-transform: uppercase;
  justify-content: space-between;
}

.viewButton {
  background: $primary-color;
  color: #ffffff;
  cursor: pointer;
  height: 31px;
  font-size: 12px;
  line-height: 31px;
  font-weight: 300;
  margin-top: 20px;
  text-align: center;
  text-transform: uppercase;
  transition: background 200ms ease, color 200ms ease;

  &.selected {
    background: $primary-dark;
    transition: background 200ms ease, color 200ms ease;
  }

  &:hover {
    background: $primary-dark;
    transition: background 200ms ease, color 200ms ease;
  }
}
