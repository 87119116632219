.commentsTitle {
  font-size: 13px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  border-top: #ffffff solid 2px;
  margin-top: 15px !important;
  padding: 20px 0 10px;
}

.comments {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}

.comment {
  margin: 0;
  padding: 7px 0;
  color: #5c5c5c;

  &:nth-child(odd) {
    color: #667094;
  }

  &+& {
    border-top: #d9dcec solid 1px;
  }
}

.commentHeader {
  font-weight: 600;
  font-size: 12px;
}

.commentDate {
  float: right;
  font-weight: normal;
  font-size: .9em;
}

.commentBody {
  font-size: 12px;
}
