@import "../../styles/variables";

.createButton {
  background: #fff;
  border: 0;
  border-radius: 5px;
  color: $primary-color;
  cursor: pointer;
  height: 38px;
  font-family: $font-family-montserrat;
  font-size: 13px;
  font-weight: bold;
  line-height: 38px;
  margin-left: 1rem;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease;
  width: 97px;
  @include withShadows

  &:hover {
    background: $primary-color;
    color: #fff;
    transition: background 200ms ease;
  }
}
