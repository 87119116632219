@import "../../../../styles/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &.actionable {
    cursor: pointer;
  }
}

.avatar {
  font-size: 11px;
  width: 32px;
  height: 32px;
  box-sizing: content-box;
  border: none;

  img { 
    width: 32px;
    height: 32px;
    background: #f3f3f3;
  }
}

.info {
  padding-right: 10px;
}

.title {
  font-size: 11px;
  color: #d0d0d0;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 6px;
}

.value {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name {
  font-size: 13px;
  margin-left: 12px;
  color: $font-grey;
}

.action {
  position: absolute;
  right: 0;
  top: 0;
  fill: $primary-color;
  cursor: pointer;

  svg {
    width: 16px !important;
    height: 16px !important;
  }
}
