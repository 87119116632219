.content {
  padding-bottom: 20px;
  position: relative;
}

.fullHeight {
  height: 100%;
  display: flex;
  flex-direction: column;

  & .body {
    flex: 1;
  }
}

.body {
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
}

.title {
  font-size: 15.8px;
  font-weight: 600;
  color: #0f0f0f;
  margin: 0;
  text-transform: uppercase;
  line-height: 1;
  display: flex;
  align-items: center;
  margin: 30px 0;

  svg {
    width: 30px;
    height: 30px;
  }
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  position: relative;
}
