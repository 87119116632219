@import '../../styles/variables.scss';
@import "../../styles/animations/fadein";

.fadeIn {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
}

.modal {
  &:global(.modal.ui) {
    width: 652px !important;
  }
  &:global(.modal.ui>.content),
  &:global(.modal.ui .actions) {
    background-color: #e5eaf3;
    border: 0;
  }
  &:global(.modal.ui>.content) {
    min-height: 500px;
  }
  :global .field textarea {
    border: 0 !important;
  }
  :global .ui.form label,
  :global .ui.form .field label {
    color: #224874;
    font-weight: 600;
  }
}

.content {
  display: flex !important;
  flex-direction: column;
}

.title {
  color: #243e6a;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  padding: 20px 0 30px;
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.comment {
  margin-top: auto !important;
  @extend .fadeIn;
}

.options {
  margin-bottom: 30px;
}

.userCard {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.userCardImgContainer {
  margin-right: 15px;
  position: relative;
  min-width: 24px;
}

.userCardImg {
  border-radius: 50%;
  display: block;
}

.userCardInfo {}

.userCardName {
  font-size: 14px;
  font-weight: bold;
  color: $font-grey;
  margin: 0;
}

.userCardReason {
  font-size: 12px;
  color: $font-grey;
  margin: 0;
}

.userCardDate {
  margin-left: auto;
  text-align: right;
  font-size: 10px;
  color: $font-grey;
}
