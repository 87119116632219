@import "../../styles/variables";

.box {
  @include rounded;
  @include withBorder;
  background-color: $white;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.close {
  line-height: 1;

  svg {
    fill: $primary-color;
  }
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;

  a:not(.close) {
    color: $primary-color;
    cursor: pointer;
    text-decoration: underline;
    font-size: 12.1px;
    font-weight: 600;
  }
}

.label {
  font-size: 16px;
  text-align: left;
  color: $font-grey;
  display: inline-block;
  line-height: 1.3;

  &.invertedTitle {
    color: $font-grey;
    background: #ffffff;
  }
}

.innerBox {
  @include rounded;
  @include withBorder;
  background-color: $white;
  padding: 10px 13px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &.blueBg {
    background-color: $duck-egg-blue;
  }
}

.innerHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  .innerBoxSmall & {
    padding-bottom: 10px;
  }
}

.innerLabel {
  @include rounded;
  background: $primary-color;
  display: inline-block;
  font-size: 11.1px;
  color: $white;
  padding: 4px 10px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 5px;

  &.innerLabelDark {
    color: #ffffff;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#4d588c), to(#2e6486));
    background-image: linear-gradient(to top, #4d588c, #2e6486);
  }

  &.innerLabelPlain {
    background: transparent;
    color: #4461b0;
    font-weight: bold;
    font-size: 13px;
  }

  &.innerLabelBold {
    font-weight: bold;
  }
}

.detailWrapper {
  width: 25%;
  color: $font-grey;
  margin-bottom: 15px;
  padding-left: 10px;

  &.sizeHalf {
    width: 50%;
  }

  &.oneThird {
    width: 33%;
  }

  &.fullWidth {
    width: 100%;
  }
}

.detailWrapperLabel {
  color: $primary-color;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 3px;

  &.capsTitle {
    text-transform: uppercase;
  }

  svg {
    cursor: pointer;
    fill: $primary-color;
    margin-left: 3px;
    transition: transform 200ms ease;

    &:hover {
      fill: $dark-slate-blue-three;
      transform: scale(1.2);
      transition: transform 200ms ease;
    }
  }
}

.detailWrapperValue {
  font-size: 15px;
}

.detailContainer {
  display: flex;
  flex-wrap: wrap;
}

.innerPageSegmentWrapper {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;

  .innerBox {
    margin-bottom: 0;
    width: 49%;
  }
}
