.checkContainer {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
  padding-bottom: 20px;

  &:last-child {
    border-bottom: 0;
  }
}

.viewMovements {
  align-items: center;
  display: flex;
  padding-bottom: 15px;
}