@import "../../styles/variables";

.badgeContainer {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  text-transform: uppercase;

  & + & {
    margin-left: 20px;
  }
}

.badge {
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.badgeTitle {
  color: #a4a4a4;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}

.red {
  border-color: #cc3f38;
  fill: #cc3f38;
}

.blue {
  border-color: #154e74;
  fill: #154e74;
  transition: background 200ms ease, fill 200ms ease;

  &:hover {
    background: #154e74;
    fill: #ffffff;
    transition: background 200ms ease, fill 200ms ease;
  }
}

.grey {
  border-color: #b0b0b0;
  fill: #b0b0b0;
}