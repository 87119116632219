@import "../../styles/variables.scss";

.topRankingListItem {
  align-items: center;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  height: 46px;
  padding: 30px 0;
  margin-bottom: 4px;
  background: white;
  color: $font-grey;
  border-bottom: 1px solid $hover-grey;

  &.first,
  &.second,
  &.third {
    .points {
      font-size: 24px;
      font-weight: 700;
    }
  }

  &.topTen {
    .position {
      color: $primary-color;
      border-color: $primary-color;
    }
  }

  &.first {
    .points {
      color: $ranking-first;
    }

    .position {
      color: $ranking-first;
      border-color: $ranking-first;
    }
  }

  &.second {
    .points {
      color: $ranking-second;
    }

    .position {
      color: $ranking-second;
      border-color: $ranking-second;
    }
  }

  &.third {
    .points {
      color: $ranking-third;
    }

    .position {
      color: $ranking-third;
      border-color: $ranking-third;
    }
  }

  &.topRankingListItemHighlight {
    position: relative;
    z-index: 1;
    transition: all 1000ms ease;
  }

  &.topRankingListItemShadow {
    box-shadow: 10px 0 200px 150px #ffffff;
    transition: all 1000ms ease;
  }

  &.topRankingListItemGrayscale {
    filter: grayscale(70%) blur(3px);
    transition: all 1000ms ease;
  }

  transition: all 1000ms ease;
}

.icon {
  min-width: 25px;

  svg{
    height: 35px;
    margin-right: 10px;
  }
}

.position {
  margin-left: 10px;
  font-size: 26px;
  color: $primary-dark;
  font-weight: bolder;
  line-height: 2.4rem;
  border-right: 2px solid $primary-dark;
  padding-right: 10px;
}

.imageContainer {
  position: relative;
}

.customerData {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;

  .customerDataName {
    font-weight: 600;
  }
}

.points {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.pointsLabel {
  font-weight: 400;
}
