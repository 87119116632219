@import "../../styles/variables.scss";

$lineWidth: 58px;
$dotSize: 12px;
$lineColor: $primary-light;

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 40px 20px;
}

.item {
  position: relative;

  &:after {
    clear: both;
  }
}

@keyframes showBody {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.body {
  position: relative;
  @include withBorder;
  width: calc(50% - #{$lineWidth / 2});
  float: left;
  transform: scale(0);
  opacity: 0;
  z-index: 2;
  transform-origin: 100% 50%;
  min-height: 50px;
  color: $font-grey;
  display: flex;
  padding: 10px;
  cursor: pointer;

  animation: showBody ease-in-out 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-delay: 1200ms;

  .item:nth-child(odd) & {
    float: right;
    transform-origin: 0 50%;
    flex-direction: row-reverse;
  }

  &:hover {
    transform: scale(2);
  }
}

.bodyBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  z-index: 1;
  border-radius: 2px;

  .withCategory & {
    opacity: .15;
  }
}

.line {
  position: absolute;
  width: $lineWidth;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
}

@keyframes showTopLine {
  from {
    height: 0;
  }
  to {
    height: 50%;
  }
}

.topLine {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 4px;
  height: 0;
  background-color: $lineColor;
  transform: translate(-50%, 0);

  .item:not(:first-child) & {
    animation: showTopLine ease-out 1;
    animation-fill-mode: forwards;
    animation-duration: 300ms;
    animation-delay: 500ms; /* Espero la animación de la línea de abajo */
  }
}

@keyframes showDot {
  0% {
    background-color: $lineColor;
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    background-color: $lineColor;
    transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    background-color: #fff;
    transform: translate(-50%, -50%) scale(1);
  }
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background-color: $lineColor;
  width: $dotSize;
  height: $dotSize;
  border: solid 2px $lineColor;
  border-radius: 50%;

  animation: showDot ease-in-out 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-delay: 0ms; /* Espero la animación de la línea de arriba */
}

.bottomLine {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 0;
  background-color: $lineColor;
  transform: translate(-50%, 0);

  .item:not(:last-child) & {
    animation: showTopLine ease-out 1;
    animation-fill-mode: forwards;
    animation-duration: 300ms;
    animation-delay: 500ms; /* Espero la animación de la línea de abajo */
  }

  .item:last-child & {
    height: 0;
  }

  .day:nth-last-child(2) & {
    height: 0;
  }
}

@keyframes showLateralLine {
  from {
    width: 0;
  }
  to {
    width: calc(50% - #{$dotSize / 2});
  }
}

.lateralLine {
  position: absolute;
  top: 50%;
  right: calc(50% + #{$dotSize / 2});
  width: 0;
  height: 1px;
  background-color: $lineColor;
  transform: translate(0, -50%);

  animation: showLateralLine ease-in-out 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-delay: 1000ms; /* Espero la animación del puntito */

  .item:nth-child(odd) & {
    right: auto;
    left: calc(50% + #{$dotSize / 2});
  }
}

@keyframes showHour {
  0% {
    opacity: 0;
    transform: translate(0, -50%) scale(0);
  }
  50% {
    transform: translate(0, -50%) scale(1.2);
  }
  100% {
    opacity: 1;
    transform: translate(0, -50%) scale(1);
  }
}

.hour {
  font-size: 10.3px;
  color: #929292;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) scale(0);
  left: calc(50% + 10px);
  white-space: nowrap;
  font-weight: 600;
  transform-origin: -20px 50%;
  opacity: 0;

  animation: showHour ease-in-out 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-delay: 2000ms;

  .item:nth-child(odd) & {
    left: auto;
    right: calc(50% + 10px);
    text-align: right;
    transform-origin: calc(100% + 20px) 50%;
  }
}

.info {
  flex: 1;
  padding: 0 12px;
  position: relative;
  z-index: 2;
}

.title {
  color: $primary-color;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 600;
}

.description {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;

  .withCategory & {
    color: #7c7c7c;
  }
}

.category {
  width: 24px;
  margin: 0 5px;
  position: relative;
  z-index: 2;
}

.categoryCircle {
  font-size: 13px;
  font-weight: 700;
  width: 24px;
  height: 24px;
  border: solid 2px #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  line-height: 20px;
}

.icon {
  position: relative;
  z-index: 2;

  svg {
    fill: $primary-color;
  }

  .withCategory & {
    svg {
      fill: $font-grey;
    }
  }
}

@keyframes showDay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.day {
  justify-content: center;
}

.dayBg {
  @include rounded;
  margin: 20px auto;
  position: relative;
  background-color: $hover-grey;
  font-size: 10.3px;
  text-align: right;
  max-width: 100px;
  color: $font-grey;
  z-index: 2;
  opacity: 0;
  padding: 0 20px;
  line-height: 16px;
  font-weight: 600;

  animation: showDay ease-in-out 1;
  animation-fill-mode: forwards;
  animation-duration: 350ms;
  animation-delay: 1000ms;

  .item:first-child & {
    margin-top: 0;
  }
}
