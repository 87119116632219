.checkTypeSelection {
  align-items: center;
  display: flex;
}

.checkTypeTitle {
  color: #4d4646;
  display: inline-block;
  font-size: 12px;
  margin-right: 5px;
}

.checkTypeSelectionSelect {
  :global {
    .ui.selection.dropdown {
      align-items: center;
      background: #e8ecf5;
      border: 0;
      border-radius: 0;
      color: #4d4646;
      display: flex;
      font-size: 12px;
      height: 28px;
      min-height: 28px;
      padding: 0;
      padding-left: 15px;
      width: 200px;

      .text {
        color: #4d4646;
      }

      .default.text {
        color: #877b7b;
      }

      .dropdown.icon {
        line-height: 0;
        margin: 0;
        top: 50%;
        padding: 0;
      }

      .menu > .item {
        background: #e8ecf5;
        color: #877b7b;
        font-size: 12px;
        padding: 6px 15px !important;

        &.selected {
          background: darken(#e8ecf5, 5);
        }

        &:hover {
          background: darken(#e8ecf5, 10);
        }
      }
    }
  }
}