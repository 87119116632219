@import "../../styles/variables.scss";

.label {
  display: flex;
  flex: 1;
  padding: 2px 10px;
  font-size: 12px;
  flex-direction: row-reverse;
  background: $white;
  align-items: center;
  color: $primary-color;
  cursor: pointer;
  display: flex;
  margin-right: 0 !important;
  margin-left: 5px;
  transition: background 200ms ease;
  white-space: nowrap;

  .iconDisplay {
    flex: 1;
    align-items: center;
    display: flex;
    fill: $font-grey;
    justify-content: flex-end;
    height: 22px;
    margin-right: 5px;
    transition: border-right-color 200ms ease;
    width: 19px;

    svg {
      transition: opacity 200ms ease;
    }
  }

  &.selected {

    &:hover {
      background: $primary-light;
    }

    .iconDisplay {
      svg {
        opacity: 1;
        fill: $primary-color;
      }
    }
  }

  &:not(.selected) {
    background: $hover-grey;
    color: $font-grey;

    &:hover {
      background: $primary-light;
    }

    .iconDisplay {
      svg {
        opacity: 0;
      }
    }
  }

  &:first-child {
    margin-left: 0;
  }
}
