.items {
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 17px;
  overflow: hidden;
  transition: height .3s ease-in-out;
  padding-right: 2px;

  &.fewElements {
    grid-template-columns: repeat(auto-fit, 200px);
  }
}

.moreBtn {
  cursor: pointer;
  text-align: center;
  padding: 10px 20px;

  svg {
    transition: transform .3s ease-in-out;
  }

  &:hover {
    svg {
      transform: rotate(360deg)
    }
  }
}
