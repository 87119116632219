@import "../../styles/variables";

.pillButton {
  background: $light-grey;
  border: 0;
  color: $font-grey;
  cursor: pointer;
  font-family: $font-family-montserrat;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 5px 10px;
  margin: 0;
  margin-left: 10px;
  outline: none;
  text-align: center;
  text-transform: uppercase;
  transition: background 200ms ease;

  &:hover {
    background: $font-grey;
    color: $light-grey;
    transition: background 200ms ease;
  }

  &:disabled {
    background: #dadde2;
    cursor: default;
  }
}
