@import "../../../styles/variables.scss";

.systematicRequiredGraphsContainer {
  align-items: flex-end;
  border-radius: 15px;
  background: $hover-grey;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 20px;
  margin-top: 20px;
  padding-top: 25px;
  position: relative;

  &.hasSubcategories {
    padding-bottom: 20px;
  }
}

.systematicGraphsContainerTitleContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  left: 0;
  top: 5px;
  width: 100%;
}

.systematicGraphsContainerTitle {
  color: $font-grey;
  font-size: 12px;
  text-transform: uppercase;
}

.systematicGraphsContainerDone {
  color: $font-grey;
  font-size: 12px;
}

.systematicGraphsContainerDoneNumber {
  font-weight: bold;
  color: $primary-color;
}
