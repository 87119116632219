@import "../../../styles/variables.scss";

.button {
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 30px;
  justify-content: center;
  text-transform: uppercase;
  width: 112px;
}

.liveButton {
  @extend .button;
  border: 1px solid #767676;
  color: #767676;
  fill: #767676;
  margin-right: 10px;
  transition: color 200ms ease, fill 200ms ease;

  &:not(.isLive):hover {
    border: 1px solid #000000;
    color: #000000;
    fill: #DB2828;
    transition: color 200ms ease, fill 200ms ease;
  }

  &.isLive {
    border: 1px solid #ffffff;
    color: #DB2828;
    cursor: default;
    fill: #DB2828;
  }
}

.liveRecordIcon {
  animation: heartbeat 10s infinite; /* IE 10+, Fx 29+ */
}

@keyframes heartbeat {
  0%   {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.playPauseButton {
  @extend .button;
  background: #e0e1e2;
  border: 1px solid #e0e1e2;
  transition: background 200ms ease, border 200ms ease;

  svg {
    margin-right: 3px;
  }

  &:hover {
    background: #c7c8c9;
    border: 1px solid #c7c8c9;
  }
}

.header {
  align-items: center;
  display: flex;
  margin-bottom: 15px;

  .title {
    flex: 1;
    color: $font-grey;
    font-size: 14px;
  }

  .actions {
    display: flex;
    flex-direction: row;
  }
}

.col {
  display: flex;
  flex: 1;
}

.row {
  display: flex;
  flex: 1;

  .col {
    padding-left: 10px;
  }

  .event {
    flex: 1;
  }
}

.heading {
  color:  #a2adba;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 0;
}

.tableScroll {
  overflow: auto;
  height: 200px;

  color: #636363;
  font-size: 11px;
  padding-right: 20px;

  .row {
    cursor: pointer;
    border-top: 1px solid #f3f6fa;

    &:hover {
      background-color: #f5f8fb;
      color: #447294;
    }

    .col {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.tablePlaceholder {
  height: 200px;
  position: relative;
}

:global {
  .event-row-enter {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }

  .event-row-enter-active {
    opacity: 1;
    max-height: 35px;
    transition: all 500ms ease-in;
  }
}

.modal {
  &:global(.modal.ui) {
    @media only screen and (min-width: 1200px) {
      width: 550px;
    }
  }
}

.list {
  background-color: #ffffff;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;

  &:first-child {
    border-top: none;
  }
}

.listItemLabel {
  font-size: 13px;
  color: #545a66;
  font-weight: 600;
}

.listItemValue {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
  padding-left: 15px;
}
