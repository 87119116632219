@import "../../../styles/variables.scss";

.container {
  min-height: 784px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $hover-grey;
}

.title {
  color: $font-grey;
  font-size: 14px;
}

.button {
  color: $primary-color;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    color: $primary-dark;
  }
}

.loaderContainer {
  min-height: 670px;
}

.noRankingMessage {
  font-weight: bold;
  font-size: 13px;
  margin-top: 200px;
  text-align: center;
}
