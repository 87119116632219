@import "../../../styles/variables.scss";

.realTime {
  display: flex;
}

.item {
  @include rounded;
  @include withBorder;
  background: $hover-grey;
  margin-right: 17px;
  flex: 1;
  padding: 2rem 20px;
  position: relative;

  svg {
    fill: #bdcaef;
    left: 0;
    position: absolute;
    top: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.highlight {
    background: $white;
  }

  &.grayed {
    &.itemCount {
      color: $font-grey;
    }
  }
}

.itemCount {
  color: $primary-color;
  font-size: 32px;
  font-weight: 700;
}

.itemTitle {
  color: $font-grey;
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 500;
}
