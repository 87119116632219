.modal {
  &:global(.modal.ui) {
    width: 652px !important;
  }
  &:global(.modal.ui .content),
  &:global(.modal.ui .actions) {
    background-color: #ebeef4;
    border: 0;
  }
  :global .field textarea {
    border: 0 !important;
  }
  :global .ui.form label,
  :global .ui.form .field label {
    color: #224874;
    font-weight: 600;
  }
  :global .ui.input input[type="text"],
  :global .ui.input input[type="date"],
  :global .ui.input input[type="time"],
  :global .ui.selection.dropdown,
  :global .react-datepicker__input-container input {
    border: 0 !important;
  }

  :global .field textarea {
    border: 0 !important;
  }
}

.title {
  color: #243e6a;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  padding: 20px 0 30px;
  text-transform: uppercase;
}