@import "../../../../../styles/variables";

.member {
  position: relative;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  transition: background-color .3s ease-in-out;

  & + & {
    border-top: transparentize(#8ea8c1, .85) solid 1px;
  }
}

.image {
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;

  svg {
    opacity: .7;
    fill: #ffffff;
  }

  img {
    border-radius: 50%;
    display: block;
    width: 50px;
    height: 50px;
  }
}

.info {
  flex: 1;
  color: $font-grey;
  margin-left: 14px;
}

.type {
  margin-bottom: 2px;
}

.name {
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 2px;
}

.phone {
}
