@import "../../../styles/variables";

.segment {
  background: #ffffff;
  padding: 30px;
}

.header {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.iconsWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
}

.isCoreIcon {
  fill: #7b9bb0;
  margin-left: auto;
}

.isMappedWrapper {
  background: $pinkish-red;
  background-image: linear-gradient(to top, $brownish-orange 1%, $pinkish-red);
  border-radius: 50px;
  margin-right: auto;
  height: 20px;
  width: 20px;
}

.isMapped {
  fill: #ffffff;
}

.listSegment {
  border-radius: 0 !important;
}

.listTitleButtonWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.createUserButton {
  display: flex;
  margin-top: -30px;
  align-items: center;

  span {
    margin-right: 10px;
  }

  &:hover {

    span {
      color: $primary-dark;
    }
  }
}

.userListTable {
  thead {
    color: $primary-color !important;
  }
}
