.myResultsHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.headerButtons {
  display: flex;
}

.myResultsHeaderPadding {
  margin-top: 11px;
}