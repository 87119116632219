.list {
  background-color: #ffffff;
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;

  &:first-child {
    border-top: none;
  }
}

.listItemLabel {
  font-size: 13px;
  color: #545a66;
  font-weight: 600;
}

.listItemValue {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
  padding-left: 15px;
}

.modal {
  &:global(.modal.ui) {
    @media only screen and (min-width: 1200px) {
      width: 550px;
    }
  }
}