.ratesContainer {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 17px;
  text-transform: uppercase;
}

.rateContainer {
  align-items: center;
  display: flex;
  margin-right: 20px;
}

.rateBox {
  background: #2e3247;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 300;
  height: 24px;
  line-height: 24px;
  margin-left: 10px;
  text-align: center;
  transition: background 200ms ease;
  width: 73px;

  &.activeSpecialRate {
    background: #8492ef;
    transition: background 200ms ease;
  }
}