.spaTimelineViewMore {
  display: block;
  text-align: center;
  font-size: 12px;
  color: rgb(35, 66, 112);
  font-weight: 600;
  border: solid 1px rgb(35, 66, 112);
  text-transform: uppercase;
  padding: 5px;
  margin: 10px;

  &:hover {
    border-color: #4183c4;
  }
}

.spaTimelineLoader {
  position: relative;
  height: 80px;
}
