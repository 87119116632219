.selectedItemDataWrapper {
  margin-top: 20px;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  z-index: 2;
}
