@import "../../../styles/variables";

.dateSelectorContainer,
.viewSelectorContainer {
  justify-content: flex-end;
  display: flex;
}

.viewSelectorContainer {
  z-index: 900;
  margin-bottom: 30px;
  margin-top: 20px;
}

.dateSelector {
  :global {
    .ui.selection.dropdown {
      align-items: center;
      border: 1px solid #d6d3d3;
      border-radius: 0;
      color: #4d4646;
      display: flex;
      font-size: 12px;
      height: 25px;
      min-height: 25px;
      padding: 0;
      padding-left: 15px;
      width: 200px;

      .text {
        color: #4d4646;
      }

      .default.text {
        color: #877b7b;
      }

      .dropdown.icon {
        line-height: 0;
        margin: 0;
        top: 50%;
        padding: 0;
      }

      .menu > .item {
        font-size: 12px;
        padding: 6px 15px !important;
      }

      &.active {
        position: relative;
        z-index: 1001;
      }
    }
  }
}

.datePickerCol {
  font-size: 12px;
  margin-left: 10px;
}

.datePickerContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  min-width: 50%;
  position: relative;
  z-index: 1000;

  :global {
    .ui.input {
      height: 30px;
      margin-right: 20px;
    }

    input {
      border: 1px solid #d6d3d3;
      border-radius: 0;
      font-family: $default-font;
      font-size: 12px;
      color: #4d4646;
      height: 25px;
      line-height: 25px;
      padding-left: 15px;
      padding-right: 15px;
      width: 200px;
    }
  }
}