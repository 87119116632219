.resultItem {
  align-items: center;
  display: flex;
  width: 100%;
}

.resultItemImage {
  fill: #4a4a4a;
  height: 39px;
  position: relative;
  margin-right: 15px;
  width: 39px;

  div {
    border: 2px solid #4a4a4a;
  }
}

.resultItemTitle {
  color: #262626;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.resultItemDesc {
  color: #8e8e8e;
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
}

.highlight {
  background: #ffff99;
}
