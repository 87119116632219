.noData {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
}

.noDataAdjusted {
  display: flex;
  flex: 1;
  position: relative;

  div {
    left: 68% !important;
    position: absolute;
    top: 400px !important;
  }
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
}

.hideFilters {
  display: none;
}