.boxPlaceholder {
    height: 160px;
    width: 100%;
    background-color: #eaeaea;
    margin-bottom: 15px;
    border-radius: 10px;
    fill: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
