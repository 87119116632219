@import "../../../styles/animations/fadein";

.step3ListContainer {
  flex: 1;
  height: 342px;
  margin-bottom: 15px;
  overflow: auto;
}

.listTable {
  tr {
    td {
      border-top: 1px solid #32374b;
      color: #ffffff;
      font-size: 12px;
      font-weight: 300;
      height: 37px;
      line-height: 37px;
    }

    &:last-child {
      td {
        border-bottom: 1px solid #32374b;
      }
    }
  }
}

.fadeIn {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
}

.animatedList {
  @extend .fadeIn;
  animation-delay: 2000ms;
}