@import "../../../styles/animations/fadein";
@import "../../../styles/variables.scss";

.input {
  input {
    color: $search-box !important;
    min-width: 340px !important;
    border-radius: 19px !important;
    opacity: 0;
    transition: all 0.6s ease;
    background: transparent !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid $search-box-icon !important;
    border-radius: 0px !important;
  }

  input:focus {
    opacity: 1;
  }

  input::placeholder {
    color: transparentize($search-box, 0.4) !important;
  }

  i {
    color: $search-box-icon;
  }
}

:global {
  .searchBoxForm {
    .search {
      .results {
        width: 340px !important;
        z-index: 99999 !important;

        .result {
          &:first-child {
            padding-top: 55px;
          }
        }
      }
    }
  }
}

.searchResultsTitle {
  display: flex;
  justify-content: space-between;
  color: $font-grey;
  background: $hover-grey;
  border: 1px solid $hover-grey;
  font-size: 12px;
  font-weight: bold;
  left: 0;
  padding: 10px 15px;
  position: absolute;
  top: 100%;
  white-space: normal;
  width: 340px;
  z-index: 100000;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 60ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
  animation-delay: 40ms;
}

.searchBoxResultItem {
  align-items: center;
  display: flex;
  width: 100%;
}

.imageContainer {
  fill: #4a4a4a;
  height: 39px;
  position: relative;
  margin-right: 15px;
  width: 39px;

  div {
    border: 2px solid $search-box-icon;
  }
}

.searchBoxResultItemTitle {
  color: #262626;
  font-size: 13px;
  font-weight: 600;
  position: relative;
}

.searchBoxResultItemDesc {
  color: #8e8e8e;
  font-size: 13px;
  font-weight: 300;
}

.searchBoxResultItemType {
  align-items: center;
  background: $hover-grey;
  color: $font-grey;
  display: flex;
  font-size: 10px;
  font-weight: 500;
  margin-left: auto;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.highlight {
  background: #ffff99;
}

.createNewClient {
  color: $font-grey;
  font-weight: normal;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
}

.createAccountIcon {
  align-items: center;
  border-radius: 100px;
  display: flex;
  fill: #4a4a4a;
  height: 39px;
  left: 0;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 39px;
}

.executive {
  padding: 0 0 0 25px;
  margin: 0 0 0 25px;
  border-left: #ccc solid 1px;

}

.executiveTop  {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

.executiveName  {
  font-size: 12px;
}
