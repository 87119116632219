@import "../../../styles/variables.scss";

.graphContainer {
  display: flex;
  height: 330px;
  position: relative;
}

.title {
  align-items: right;
  color: $font-grey;
  display: flex;
  font-size: 14px;
  height: 34px;
  position: absolute;
}

.graphTotalPersonsContainer {
  justify-content: center;
  color: $font-grey;
  align-items: center;
  display: flex;
  font-size: 10px;
  position: absolute;
  text-transform: uppercase;
  right: 0;
}

.totalPersonsValue {
  color: $primary-color;
  display: block;
  font-size: 18px;
  font-weight: 600;
  height: 34px;
  line-height: 34px;
  padding-left: 15px;
  padding-right: 15px;
}

.personaImageContainer {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 50px;
  position: relative;
  width: 90px;
}

.teamPersons {
  color: $font-grey;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  margin-top: 10px;
  text-align: right;
  text-transform: uppercase;
  width: 100%;
}
