@import "../../styles/variables";

.modalMonthFilter {
  align-items: center;
  background: #dee5f3;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px;
}

.modalMonthFilterPillTitle {
  background: #ffffff;
  border-radius: 3px;
  color: $dark-slate-blue;
  height: 23px;
  line-height: 23px;
  padding-left: 10px;
  padding-right: 10px;
}

.modalMonthFilterSelects {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-width: 50%;

  :global {
    .ui.input {
      height: 30px;
      margin-left: 10px;
      margin-right: 20px;
    }

    input {
      border: 1px solid #b2b2b2;
      border-radius: 5px;
      font-family: $default-font;
      color: $greyish-brown;
      height: 30px;
      line-height: 30px;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
    }
  }
}

.modalMonthFilterLabel {
  display: inline-block;
  margin-left: 20px;
  margin-right: 10px;
}

.modalMonthFilterButton {
  align-items: center;
  background: $dark-slate-blue;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  fill: #ffffff;
  justify-content: center;
  height: 30px;
  margin: 0;
  margin-left: 20px;
  padding: 0;
  width: 30px;
}