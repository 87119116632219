@import '../../styles/variables.scss';
@import "../../styles/animations/fadein";

.fadeIn {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
}

.dropdownContainer {
  background: white;
  @include withBorder;
  @include withShadows;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 679px;
  right: 268px;
  top: 35px;
  /*padding: 25px;*/
  position: absolute;
  /*width: 435px;*/
  z-index: 999;

  @extend .fadeIn;
}

.dropdownContainerShowHelp {
  /*width: 690px;*/
}

.dropdownTitleContainer {
  background: $primary-color;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-top: 20px;
  padding-right: 25px;
  position: relative;
}

.dropdownTitleHelpContainer {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  align-items: center;
  display: flex;
  padding-right: 15px;
}

.dropdownTitle {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  line-height: 14px;
  padding-right: 10px;
  width: 230px;

  @extend .fadeIn;
  animation-delay: .2s;

  &.hasHelpVisible {
    width: 250px;
  }
}

.helpIcon {
  cursor: pointer;
  fill: #ffffff;
  margin-left: 15px;
  transition: fill 200ms ease;

  @extend .fadeIn;
  animation-delay: .2s;

  &.active {
    fill: #b4bdff;
    transition: fill 200ms ease;
  }
}

.dropdownCloseIcon {
  cursor: pointer;
  fill: #ffffff;

  @extend .fadeIn;
  animation-delay: .2s;
}

.dropdownPauseIcon {
  cursor: pointer;
  fill: #ffffff;
  margin-right: 3px;

  @extend .fadeIn;
  animation-delay: .2s;
}

.customerProfileCtasDropdownTop {
  color: $font-grey;
  overflow: auto;
  height: 55%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 15px;
  padding-top: 16px;
}

.customerProfileCtasDropdownBottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  padding: 20px;
}

.ctasTreeContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 405px;
}

.bodyContainer {
  display: flex;
  height: 100%;
}

.bottomTitle {
  @extend .fadeIn;

  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}

.commentTextareContainer {
  @extend .fadeIn;

  animation-delay: 0.2s;
  margin-bottom: 15px;
  margin-top: 15px;
  flex: 1;
}

.commentTextarea {
  font-family: 'Muli' !important;
  background: #ffffff;
  color: $font-grey;
  @include withBorder;
  @include withShadows;
  @include rounded;
  padding: 10px;
  height: 172px;
  outline: none;
  width: 100%;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.buttonsContainer {
  display: flex;
  padding-top: 0;

  .goBackButton {
      margin-right: 30px;
  }

  &.finishedSelection {
    padding-bottom: 25px;
  }
}

.button {
  align-items: center;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-family: $font-family-montserrat;
  font-size: 13px;
  font-weight: 600;
  justify-content: center;
  height: 34px;
  outline: none;
  text-transform: uppercase;
  transition: background 200ms ease;
}

.goBackButton {
  @extend .button;
  @extend .fadeIn;
  color: $font-grey;
  animation-delay: .2s;
  border: 1px solid $font-grey;
  background: transparent;

  &:hover {
    background: $font-grey;
    color: white;
    transition: background 400ms ease;
  }
}

.actionButton {
  @extend .button;
  @extend .fadeIn;

  animation-delay: 0.4s;
  border: 0;
  background: $primary-color;

  &:hover {
    background: $primary-dark;
    transition: background 200ms ease;
  }

  &.actionButtonLoading {
    position: relative;
  }
}

.actionButtonEmpty {
  display: flex;
  flex: 1;
}
