$animation-duration: 500ms;

:global {
  .creditSimulatorModalBg {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  .creditSimulatorModalContainer {
    /*background: rgba(0, 0, 0, 0.5);*/
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    transition: opacity $animation-duration ease;
    width: 100%;
    z-index: 9000;

    &-enter {
      opacity: 0;

      .creditSimulatorModal {
        animation-name: zoomIn;
        animation-duration: $animation-duration;
      }
    }

    &-enter-active,
    &-enter-done {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;

      .creditSimulatorModal {
        animation-name: zoomOut;
        animation-duration: $animation-duration;
      }
    }
  }

  .creditSimulatorModal {
    border-radius: 5px;
    display: flex;
    height: 679px;
    margin: auto;
    margin-top: 50px;
    transition: all $animation-duration ease;
    overflow: hidden;
    position: relative;
    width: 935px;
    z-index: 9001;

    @media screen and (max-height: 800px) {
      margin-top: 20px;
    }
  }
}

@keyframes zoomIn{
  0% {
    opacity: 0;
    transform: scale(.2)
  }

  100% {
    opacity: 1;
    transform: scale(1)
  }
}

@keyframes zoomOut{
  0% {
    opacity: 1;
    transform: scale(1)
  }

  100% {
    opacity: 0;
    transform: scale(0)
  }
}
