@import "../../styles/variables.scss";

.box {
  @include withBorder;
  border-radius: 3px;
  background: $white;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 128px;
  padding: 2rem;
  position: relative;
  transition: background 200ms ease;
  min-width: 250px;

  &:hover {
    background: #f6f6f7;
    transition: background 200ms ease;
  }

  &.primary {
    color: #383839;
    fill: #383839;
  }

  &.secondary {
    color: #5b8ec7;
    fill: #5b8ec7;
  }

  &.other {
    color: #2a4a7a;
    fill: #2a4a7a;
  }

  &.ok {
    .value {
      color: #50992c;
      fill: #50992c;
    }
  }

  &.error {
    .value {
      color: #e75c4a;
      fill: #e75c4a;
    }
  }

  .value {
    font-size: 38px;
    font-weight: 700;
    line-height: 51px;
    white-space: nowrap;
  }

  .title {
    font-size: 14px;
    text-transform: uppercase;
    color: $font-grey;
    margin-bottom: 10px;
  }

  .desc {
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
  }
}
