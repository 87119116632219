@import "../../../styles/variables.scss";

.closeIcon {
  align-items: center;
  background: $primary-color;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  fill: #ffffff;
  height: 28px;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: transform 200ms ease;
  width: 28px;

  &:hover {
    transform: scale(1.1);
    transition: transform 200ms ease;
  }
}
