.modal {
  &:global(.modal.ui) {
    width: 652px !important;
  }
  &:global(.modal.ui .content),
  &:global(.modal.ui .actions) {
    background-color: #ebeef4;
    border: 0;
  }
  &:global(.modal.ui .content) {
    //min-height: 500px;
  }
  :global .field textarea {
    border: 0 !important;
  }
  :global .ui.form label,
  :global .ui.form .field label {
    color: #224874;
    font-weight: 600;
  }
}

.content {
}

.title {
  color: #243e6a;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  padding: 20px 0 30px;
  text-transform: uppercase;
}

.select {
  :global(.ui.fluid.selection.dropdown) {
    border: 0;
  }
}

.executive {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.executiveImg {
  margin-bottom: 20px;
}

.executiveAvatar {
  font-size: 55px;
  border-color: #234270;
  border-width: 5px;
}

.executiveName {
  color: #262626;
  font-size: 24px;
  font-weight: 700;
}