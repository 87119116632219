@import "../../../styles/variables.scss";

.selectContainer {
  :global {
    .ui.selection.dropdown {
      border: none !important;
      background: $background;
      min-height: 30px !important;

      .menu {
        background: $background !important;
        border: 0;
      }

      .item {
        border: 0 !important;
      }

      .text {
        color: $font-grey !important;
        font-size: 13px !important;
      }

      .icon {
        cursor: pointer;
        color: $primary-color;
        height: auto;
        opacity: 0.8;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translate(0, -50%);
        transition: opacity 0.1s ease;
        width: auto;
        z-index: 3;
      }
    }
  }
}
