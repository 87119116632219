@import "../../styles/variables";

.lastUpdateWrapper {
  align-items: center;
  display: flex;
}

.lastUpdateInnerWrapper {
  flex-grow: 1;
  margin-left: 20px;
}

.field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 5px;
}

.fieldName {
  white-space: nowrap;
  font-size: 13px;
  color: $dark-slate-blue-three;
  margin-right: 15px;
  font-weight: 600;
  padding-bottom: 5px;
  min-width: 155px;
}

.fieldValue {
  align-items: center;
  flex: 1;
  font-size: 13px;
  color: $grey-blue;
  border-bottom: 4px solid #ffffff;
  padding: 5px 10px;
  font-weight: 500;
}

.image {
  height: 60px;
  position: relative;
  width: 60px;
}

.lastUpdateNoImage {
  align-items: center;
  background-color: #fbfbfb;
  border: 2px solid #ffffff;
  border-radius: 100px;
  display: flex;
  fill: $dark-slate-blue-three;
  height: 60px;
  justify-content: center;
  width: 60px;
}