@import "../../../styles/variables";

.sidebar {
  background-color: white;
  padding: 12px 13px;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.subHeader {
  line-height: 1.77;
  color: $font-grey;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  padding: 20px 0;
}

.subHeaderAction {
  font-size: 11.2px;
  line-height: 2.21;
  color: #909299;
  font-weight: 600;
  margin-left: auto;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
}

.formGroup {
  margin-bottom: 2px;

  label {
    font-size: 12px !important;
    font-weight: 600;
    color: #888f9f !important;
  }
}

.appliedOption {
  margin-bottom: 2px;

  label {
    font-size: 12px;
    font-weight: 600;
    color: #888f9f;
  }

  & + & {
    border-top: #fafafa solid 1px;
  }

  &:before {
    content: '✓';
    display: inline-block;
    padding-right: 10px;
    color: #aaa
  }
}

.filterCount {
  color: $primary-color;
}
