.pageWrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  width: 100%;
}

.notFoundWrapper {
  align-items: center;
  background: #ffffff;
  border-radius: 5px;
  color: #91abc1;
  display: flex;
  fill: #91abc1;
  font-weight: bold;
  justify-content: center;
  height: 500px;
  width: 100%;
}

.titleWrapper {
  margin-left: 15px;
}

.title {
  font-size: 66px;
  line-height: 66px;
}

.desc {
  font-size: 15px;
}