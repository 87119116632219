.uploadInput {
  position: fixed;
  top: -100em;
}

.imageTitle {
  display: block;
  margin-left: 5px;
  color: #545454;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;

  .error & {
    color: #eb1a3c;
  }
}

.errorMessage {
  color: #eb1a3c;
  display: inline-block;
  font-weight: bold;
  margin-left: 20px;
}

.imageContainer {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  margin-top: 10px;
}

.image {
  border-radius: 100px;
  height: 70px;
  margin-right: 15px;
  width: 70px;
}

.noImage {
  @extend .image;

  align-items: center;
  display: flex;
  background: #f3f5f9;
  border: 2px solid #234270;
  justify-content: center;
  fill: #234270;
}

.selectImageButton {
  background: #D3D3D3;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  transition: background 200ms ease;

  &:hover {
    background: #b2b2b2;
    transition: background 200ms ease;
  }
}