@import "../../../../styles/variables";

.filterGroup {
  margin-bottom: 4px;
}

.header {
  border-bottom: solid 1px $hover-grey;
  font-size: 12px;
  line-height: 1;
  color: $font-grey;
  padding: 0 7px 0 0;
  height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  transition: background-color .3s ease-in-out, color .3s ease-in-out;
  cursor: pointer;

  .applied & {
    border-color: $primary-color;
    color: $primary-color;
  }
}

.headerIcon {
  display: block;
  margin-left: auto;
  transition: transform .3s ease-in-out;

  svg {
    transition: fill .5s ease-in-out;
  }

  .applied & {
    transform: rotate(180deg);
  }

  .expanded & {
    transform: rotate(180deg);
  }
}

.body {
  display: none;
  padding: 10px 0;

  .expanded & {
    display: block;
  }
}

:global(.ui.checkbox input:checked ~ .box:after, .ui.checkbox input:checked ~ label:after) {
  content: "";
}
