@import '../../styles/variables.scss';

.timeline {
}

.header {
  position: relative;
  display: flex;
  align-items: center;
}

.title {
  flex: 1;
  font-size: 16.1px;
  font-weight: 500;
  line-height: 1.54;
  color: #0f0f0f;
  font-family: $font-family-montserrat;
  text-transform: uppercase;
  padding: 10px 0;
}

.search {
  flex: 1;
}

.searchedTermClose {
  float: right;
}

.body {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: $white;
  padding: 14px;
  height: 60vh;
  overflow-y: auto;
  @include withBorder;

  &::-webkit-scrollbar {
    width: 6px !important;
    border-radius: 100% !important;
  }

  &::-webkit-scrollbar-track {
    width: 4px !important;
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px !important;
    background-clip: padding-box;
    background: $font-grey !important;
  }
}

.creationForm {
  padding: 10px;
  background: $hover-grey;
  display: flex;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
  @include withBorder;

  & > textarea {
  }
}

.creationFormInput {
  flex: 1;
  border-radius: 15px !important;
  background-color: #ffffff;
  border: 0;
  min-height: 36px;
  padding: 8px 7px 10px 8px;
  line-height: 1.3;
}

.creationFormBtn {
  display: flex;
  align-items: center;
}

.creationFormBtn:not(.creationFormBtnMic) {
  position: absolute;
  top: 50%;
  right: 65px;
  transform: translateY(-50%);

  svg {
    fill: $font-grey;
  }
}

.creationFormBtnMic {
  svg {
    fill: $primary-color;
    margin: 0 10px;
  }

  &.listening {
    svg {
      fill: $error-color;
    }
  }
}

/* Esto es para pisar estilos de semantic */
.timeline .creationForm button.creationFormBtn {
  border-radius: 2px;
  padding: 0;
  width: 36px !important;
  height: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0 0 5px;

  svg {
    fill: $primary-color
  }
}

.timeline .creationForm button.creationFormBtnMic {
  background: #7699c0 !important;
}

.groups {
  margin-bottom: 15px;
}

.group {
  background-color: #ffffff;
  margin-top: 17px;
  text-align: center;
}

.groupTitle {
  font-size: 10px;
  color: $white;
  text-transform: uppercase;
  background: $font-grey;
  border-radius: 15px;
  display: inline-flex;
  padding: 2px 4px;
  text-align: center;
  margin: 0 auto;
  color: white;
}

.groupItems {
  padding: 10px;
  text-align: left;
}

.loader {
  position: relative;
  min-height: 100px;
}

.placeholder {
  color: #9d9d9d;
  font-weight: 600;
  margin-top: 15px;
  background-color: #f8f8f8;
  position: relative;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seeMore {
  margin-top: 15px !important;
}
