.placeholder {
   align-items: center;
   display: flex;
   fill: #979797;
   height: 100%;
   left: 0;
   justify-content: center;
   position: absolute;
   top: 0;
   width: 100%;
 }