.avatar {
  border: solid 3px #ffffff;
  border-radius: 50%;
  display: block;
  font-size: inherit;
  height: 32px;
  overflow: hidden;
  position: relative;
  width: 32px;

  img {
    height: 32px;
    width: 32px;
  }
}

.noBorder {
  border: 0;
}