@import "../../../styles/variables";

.expandedSearchFilter {
  align-items: center;
  color: $search-no-client-main;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  margin-left: 15px;
  text-transform: uppercase;
  user-select: none;

  &.isClient {
    color: $search-client-main;
  }
}

.expandedSearchFilterCheckbox {
  align-items: center;
  border: 1px solid $search-no-client-main;
  display: flex;
  fill: $search-no-client-main;
  justify-content: center;
  margin-right: 10px;
  height: 20px;
  width: 20px;

  .isClient & {
    fill: $search-client-main;
  }


  .isClient & {
    border: 1px solid $search-client-main;
  }
}