.gaugeDetailContainer {
  display: flex;
  margin-top: 20px;
}

.detailLoading {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 250px;
}

.detailWrapper {
  background: #ffffff;
  border-top-left-radius: 30px;
  display: flex;
  flex: 1;
  padding: 20px;
  padding-right: 40px;
  position: relative;
}

.misteryShopper {
  align-items: center;
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  height: 100%;
  text-align: center;
  width: 100%;
}

.defaultDetail {
  display: flex;
  flex: 1;
}

.detailLeft {
  width: 270px;
}

.detailRight {
  flex: 1;
  padding-left: 60px;
  width: 100px;
}

.title {
  color: #7699c0;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.detailList {
  list-style: none;
  padding: 0;

  li {
    border-bottom: 1px solid #e0e0e0;
    color: #2b3d4f;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;

    &.bold {
      font-weight: 700;
    }
  }
}

.viewCtas {
  color: #2b3d4f;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  text-decoration: underline;
  text-transform: uppercase;
}

.descText {
  color: #2b3d4f;
  font-size: 12px;
  font-weight: 600;
  margin-top: 40px;
  white-space: pre-line;
}

.expandButton {
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 30px;
  justify-content: center;
  transition: background 200ms ease;

  &:hover {
    background: rgba(#9ecdff, 0.3);
    transition: background 200ms ease;
  }
}