@import "../../../../../../styles/variables";

.filters {
  @include withBorder;
  @include rounded;
  background-color: $white;
  padding: 5px;
  margin-bottom: 16px;
}

.header {
  padding: 6px;
}

.tag {
  @include rounded;
  display: inline-block;
  font-size: 11.1px;
  color: $white;
  background-color: $primary-color;
  padding: 2px 10px;
}

.body {
  padding: 6px;
}

.row {
  display: flex;
}

.byDate {
  font-size: 11.6px;
  color: #23426e;
  font-weight: 700;
  line-height: 30px;
}

.group {
  flex: 1;
  padding: 0 5px;

  label {
    font-size: 12.7px;
    color: #474747;
    font-weight: 600;
    padding-right: 10px;
    line-height: 30px;
  }
}

.helpBlock {
  text-align: right;
  font-size: 10.6px;
  color: #7e8695;
  font-weight: 500;
}

.control {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}

.submit {
  margin-left: auto;
}

.button {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:global(.ui.primary.button) {
    min-height: 30px !important;
    height: 30px !important;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.footer {
  padding: 6px;
  font-size: 10.6px;
  line-height: 1.2;
  color: #667995;
  font-weight: 500;
}

.yearSelect {
  margin-left: 5px;
  width: 100px;

  &:global(.ui.selection.dropdown) {
    min-height: 30px !important;
    height: 30px !important;
    border: 0;
    font-size: 13px;
  }
}

.monthSelect {
  width: 140px;

  &:global(.ui.selection.dropdown) {
    min-height: 30px !important;
    height: 30px !important;
    border: 0;
    font-size: 13px;
  }
}
