@import "../../../styles/variables";

.amountContainer {
  color: #ffffff;
  display: flex;
  font-size: 10px;
  font-weight: 300;
  justify-content: space-between;
  margin-bottom: 3px;
  text-transform: uppercase;
}

.number {
  font-size: 13px;
  font-weight: 400;
  text-align: right;
  padding-right: 24px;
  position: relative;
  width: 112px;

  &.last {
    border-bottom: 1px solid #e6e9f9;
    padding-bottom: 4px;
  }

  svg {
    cursor: pointer;
    fill: #efefef;
    position: absolute;
    right: 2px;
    top: 4px;
    transition: fill 200ms ease;

    &:hover {
      fill: #ffffff;
      transition: fill 200ms ease;
    }
  }
}

.editableInput {
  background: transparent;
  border: none;
  color: #ffffff;
  flex: 1;
  font-family: $default-font;
  font-size: 13px;
  font-weight: 400;
  text-align: right;
  transition: background 200ms ease;
  padding: 5px;
  position: relative;
  right: -5px;
  width: 100%;

  &.editMode {
    background: rgba(255, 255, 255, 0.07);
    border-radius: 5px;
    outline: none;
    transition: background 200ms ease;
  }
}