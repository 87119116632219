.wrapper {
  border: #eee solid 1px;
  background-color: #F9FAFB;
  margin: 0;
  padding: 0;
}

.item {
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;

  & + & {
    border-top: #eee solid 1px;
  }

  & > span {
    cursor: pointer;
    margin-left: auto;
    padding: 10px;
    display: block;

    svg {
      fill: #7a99b9;
    }
  }
}
