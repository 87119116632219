@import "../../../styles/variables";

.container {
  position: relative;
  overflow: hidden;
  transition: all .5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 300px);
}

.item {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  transition: all 1.5s ease-in-out;
  opacity: 1;
  overflow: hidden;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all .3s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 0.1;
      background-color: $primary-color;
    }
  }

  &.active {
    &:after {
      opacity: 0.58;
      background-color: $primary-color;
    }
  }
}

.itemThumb {
  width: 100%;
  height: 100%;
  filter: saturate(1);
  transition: all .3s ease-in-out;
  background: #eaeaea center center no-repeat;
  background-size: cover;

  .item:hover & {
    filter: saturate(.5);
  }

  .disabled & {
    filter: saturate(.1);
  }

  .active & {
    filter: saturate(.1);
  }
}

.dimmerHiddenItems {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparentize($primary-color, .15);
  transition: all .5s ease-in-out;
  color: #ffffff;
  z-index: 20;
  display: flex;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}
