@import "../../../styles/variables.scss";

.title {
  border-bottom: 1px solid #ffffff;
  color: $font-grey;
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 10px;
  padding-bottom: 10px;
}

.tableContainer {
  padding-right: 10px;
}

.table {
  width: 100%;

  thead {
    tr {
      td {
        color: $font-grey;
        font-size: 11px;
        font-weight: bold;
        height: 40px;
        line-height: 40px;
        text-transform: uppercase;
      }
    }
  }

  tbody {
    tr {
      td {
        color: $font-grey;
        font-size: 12px;
        font-weight: bold;
        height: 40px;
        line-height: 40px;

        &:first-child {
          text-align: center;
        }
      }

      &:nth-child(odd) {
        background: $hover-grey;
      }
    }
  }

  .caps {
    text-transform: uppercase;
  }

  .shortResponse {
    text-decoration: underline;
  }

  .gray {
    color: #404040;
  }

  .userImage {
    border-radius: 50px;
    height: 35px;
    float: left;
    width: 35px;
  }

  .dateCol {
    width: 95px;
    text-align: center;
    text-transform: uppercase;
  }

  .photoCol {
    vertical-align: middle;
    text-align: center;
    width: 45px;
  }

  .valueCol {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    padding-right: 10px;

    svg {
      fill: $success-color;
      margin-left: 5px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.ctasBoxesContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  margin-top: 59px;
  min-height: 250px;
}

.imageContainer {
  position: relative;
  height: 35px;
  width: 35px;
}

.valueContainer {
  text-align: right;
  padding-right: 10px;
}

.valueArrowContainer {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  height: 100%;
  width: 100%;

  svg {
    margin-left: 5px;
  }
}

.saleDirectionDown {
  color: $error-color;
  fill: $error-color;
}

.saleDirectionUp {
  color: $success-color;
  fill: $success-color;
}

.multipleAccountIcon {
  align-items: center;
  display: flex;
}

.saleDetailsPopup {
  font-size: 12px;
}

.soldBy {
  margin-bottom: 10px;
}

.noResultsFound {
  font-weight: bold;
  margin-top: 50px;
  text-align: center;
}

.boxValue {
  font-size: 17px;
  font-weight: bold;

  &.ok {
    color: $success-color;
  }

  &.error {
    color: $error-color;
  }
}

.divFloat {
  float: right;
}