@import "../../../../../../styles/variables";

.signatoryOwnerType {
  background: $dusty-orange;
  border-radius: 100px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  padding-left: 15px;
  padding-right: 15px;
}

.signatoryLink {
  align-items: center;
  background: $primary-color;
  border-radius: 100px;
  display: flex;
  fill: #ffffff;
  justify-content: center;
  height: 25px;
  transition: background 200ms ease;
  width: 25px;

  &:hover {
    background: $primary-dark;
    transition: background 200ms ease;
  }
}

.signatoryImage {
  max-width: 100px;
}

.signatoryFmc {
  text-align: right;
}
