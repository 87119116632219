@import "../../../../styles/variables";

.body {
  padding: 0 8px;
}

.range {
  padding: 0 5px 30px;
}

.values {
  font-size: 12px;
  color: $primary-color;
  font-weight: 600;
}
