.inputContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 10px;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;

  input {
    border-radius: 100px;
    border: 1px solid #c0c0c0;
    height: 30px;
    line-height: 30px;
    outline: none;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  svg {
    fill: #494949;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
  }
}