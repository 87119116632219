@import "../../../styles/variables.scss";

.pager {
  display: flex;
  list-style: none;
}

.pagerPage {
  color: $font-grey;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
  transition: color 200ms ease, background 200ms ease;

  &:hover {
    color: $primary-color;
    transition: color 200ms ease;
  }

  &.active {
    background: $primary-color;
    border-radius: 3px;
    color: white;
  }
}

.pagerContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
