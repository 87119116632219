@import "../../../../../styles/variables";

.itemsList {
  list-style: none;
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
}

.item {
  border-bottom: 1px solid $hover-grey;
  color: $font-grey;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  height: 28px;
  line-height: 28px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  transition: background 200ms ease;

  &.active {
    background: #ffffff;
    transition: background 200ms ease;
  }

  &:first-child {
    border-top: 1px solid #ffffff;
  }

  &:hover {
    background: #ffffff;
    transition: background 200ms ease;
  }

  svg {
    fill: #a0a4af;
    position: absolute;
    right: 15px;
    top: 5px;
  }
}

.description {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 400;
  min-height: 300px;
}

.minHeight {
  min-height: 300px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.button {
  display: inline-block;
  background: $primary-color;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
  height: 32px;
  line-height: 32px;
  padding-left: 15px;
  padding-right: 15px;
  text-transform: uppercase;

  &:hover {
    background: #092650;
    color: #ffffff;
  }
}
