@import "../../styles/variables";

.topButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-content: flex-end;
  margin-bottom: 23px;
}

.arrowsGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
}

.topButtonsRight {
  margin-left: 15px;
}

.arrowIcon {
  cursor: pointer;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.5s ease;

  & svg {
    fill: $font-grey;
  }

  &:hover {
    background: $hover-grey;
  }

  &:hover svg {
    fill: $light-grey;
  }
}

.bottomButtonsContainer {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;

  > * {
    margin-right: 20px;
  }

  :last-child {
    margin-right: 0;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #4c4c4c;
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  border-right: #aeb0be solid 1px;
}

.link {
  display: inline-flex;
  align-items: center;
  color: #234270;
  font-size: 12px;
  font-weight: 600;
  float: right;
  text-decoration: underline;

  svg {
    fill: #234270;
    width: 1.2em;
    height: 1.2em;
    margin-left: 10px;
  }
}
