.buttonContainer {
  padding: 10px;
}

.button {
  align-items: center;
  display: flex;
  cursor: pointer;
  color: #1f618b;
  fill: #1f618b;
  text-decoration: underline;

  svg {
    margin-right: 5px;
  }
}