@import "../../../styles/variables.scss";

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    font-size: 13px;
    line-height: 19px;
  }
}

.iconContainer {
  cursor: pointer;
  fill: $primary-color;
  position: absolute;
  right: 0;
  top: 5px;
}

.phoneLink,
.emailLink {
  align-items: center;
  color: $primary-color;
  display: flex;
  transition: color 200ms ease;

  svg {
    fill: $primary-color;
    margin-right: -17px;
    position: relative;
    top: -1px;
    left: -20px;
    transition: fill 200ms ease;
  }

  &:hover {
    color: $primary-dark;
    transition: color 200ms ease;

    svg {
      fill: darken(#4183C4, 20%);
      transition: fill 200ms ease;
    }
  }
}

.emailLink {
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
