.floatingImage {
  border-radius: 50%;
  height: 55px;
  margin: 0 auto 10px;
  overflow: hidden;
  position: absolute;
  width: 55px;

  img {
    height: 100%;
    width: 100%;
  }
}