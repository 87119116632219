@import "../../../../styles/variables";

.mappingBoxWrapper {
  margin-top: 30px;
}

.doMapButton {
  margin-left: auto !important;
}

.mappingBoxTitles {
  align-items: center;
  border-bottom: 5px solid #eff1f4;
  display: flex;
  height: 45px;
}

.mappingBoxTitle {
  min-width: 25%;
}

.mappingBoxWrapper {
  list-style: none;
  margin: 0;
  margin-top: 15px;
  padding: 0;
}

.mappingBoxItem {
  align-items: center;
  background-color: #e7eaed;
  border: solid 1px #dadada;
  border-radius: 5px;
  cursor: move;
  display: flex;
  justify-content: flex-start;
  height: 49px;
  margin-bottom: 8px;
  opacity: 1;
  padding-left: 15px;
  padding-right: 15px;
  transition: background 200ms ease, color 200ms ease;

  &:hover {
    background: #6f8aa6;
    color: #ffffff;
    transition: background 200ms ease, color 200ms ease;
  }
}

.mappingBoxItemNotVisible {
  opacity: 0;
}

.mappingBoxItemHidden {
  display: none;
}

.mappingBoxItemLabel {
  margin-right: 15px;
  min-width: 25%;
}

.mappingBoxItemAction {
  margin-left: auto;
  display: flex;
}

.mappingBoxItemActionDeleteContainer {
  align-items: center;
  background: $main-color;
  border-radius: 5px;
  fill: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 31px;
  margin-right: 10px;
  opacity: 0;
  transition: opacity 200ms ease;
  width: 42px;

  .mappingBoxItem:hover & {
    opacity: 1;
    transition: opacity 200ms ease;
  }
}

.mappingBoxItemActionContainer {
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  fill: $main-color;
  height: 26px;
  justify-content: center;
  width: 26px;
}