@import "../../styles/variables.scss";

$loginInputHeight: 42px;

.loginInputContainer {
  position: relative;
  height: $loginInputHeight;
  margin-bottom: 32px;
  width: 80%;
  border-bottom: 1px solid $login-form;
  font-size: 16px;
  line-height: 10px;
}

.loginInput {
  background: transparentize(#ffffff, 0.79);
  color: $login-form !important;
  height: $loginInputHeight;
  line-height: $loginInputHeight;
  margin: 0;
  padding: 0;
  padding-left: 5px;
  outline: none;
  transition: background 300ms ease;
  width: 100%;

  &::placeholder {
    color: transparentize($login-form, 0.3);
    transition: color 300ms ease;
  }

  &:focus {
    background: transparentize($login-form, 0.60);
    transition: background 300ms ease;

    &::placeholder {
      color: transparentize($login-form, 0.3);
      transition: color 300ms ease;
    }
  }
}
