.title {
  align-items: center;
  display: flex;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;

  svg {
    margin-right: 5px;
  }
}