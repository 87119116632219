@import "../../styles/variables";

.filtersBarContainer {
  @include withBorder;
  border-radius: 15px;
  overflow: hidden;
}

.filtersBarTop {
  padding: 15px;
  align-items: center;
  background: $white;
  display: flex;
  justify-content: flex-end;
  height: 44px;
}

.filtersBarTopTitle {
  color: $main-color;
  font-weight: 600;
  margin-right: auto;
  text-transform: uppercase;
}

.filterBarButtonSearch {
  order: 9999;
}
