@import "../../styles/variables.scss";

.myResultsContainer {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	flex: 1;
	margin-top: 30px;
	font-family: Muli !important;
}

.pageContent {
  display: flex;
  align-items: stretch;
	height: 100%;
}

.header {
	display: flex;
	align-items: flex-start;
}

.pageSidebar {
  width: 226px;
  margin-right: 25px;
	display: flex;
	background: white;
	@include withBorder;
}

.myResultsLeft {
	width: 100%;
}

.myResultsRight {
  position: relative;
}

.myResultsRightContainer {
  position: relative;
  transition: filter 200ms ease;
}

.myResultsRightContainerBlur {
  filter: blur(5px);
}

.blurOverlay {
  background: rgba(255, 255, 255, 0.1);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
