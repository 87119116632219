.documentInputsWrapper {
  align-items: center;
  display: flex;

  :global {
    .field:not(.wide) {
      flex: 1;
      margin-bottom: 11px !important;
      margin-left: 15px !important;
      width: 100%;
    }
  }
}

.documentLabel {
  color: #545454;
  font-weight: 500;
}

.error {
  color: #eb1a3c;
}