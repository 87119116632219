.container {
  position: relative;
}

.paginationContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.notFoundContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 150px;
}

.errorContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 150px;
}