@import '../../styles/variables.scss';

.circleButton {
  align-items: center;
  background: $primary-button;
  border: 0;
  border-radius: 50% !important;
  cursor: pointer;
  display: flex;
  fill: #ffffff;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  transition: all 200ms ease;

  & > svg {
    vertical-align: middle;
  }

  &:hover {
    background: white;
    transition: all 200ms ease;
    @include withBorder

    & > svg {
      fill: $primary-color;
    }
  }
}

.closeModal {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  transform: translate(50%, -50%) !important;
}
