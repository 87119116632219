@import "../../styles/variables.scss";

.dailyGoalLine {
  animation-name: dailyGoalAnimate;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  border-bottom: 1px solid #ff5e5d;
  position: absolute;
  width: 100%;
}

.dailyGoalLabel {
  color: $white;
  background: $primary-color;
  font-size: 10px;
  border-radius: 50px;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  position: absolute;
  right: 0;
  transform: translate(100%, -50%);
  width: 55px
}
