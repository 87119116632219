.reports {
  border-radius: 5px;
  background: #ffffff;
  max-height: 320px;
  overflow: auto;
}

.reportsLoading {
  height: 522px;
  position: relative;
}

.list {
  list-style: none;
  padding: 0;

  li {
    color: #415b7f;
    cursor: pointer;
    border-bottom: 1px solid #f3f5f9;
    font-size: 11px;
    font-weight: 600;
    margin-left: 7px;
    margin-right: 7px;
    padding-bottom: 5px;
    padding-top: 5px;

    &:hover {
      font-weight: bold;
    }
  }
}