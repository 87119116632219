@import "../../styles/variables.scss";

.subHeader {
  background-color: $white;
  @include rounded;
  @include withBorder;
  padding: 20px;
  color: $font-grey;
  margin-bottom: 15px;
}

.subHeaderTitle {
  font-size: 19.1px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.subHeaderSubtitle {
  font-size: 15.6px;
  margin-bottom: 10px;
}

.subHeaderAmount {
  font-size: 26.9px;
  color: $primary-color;
}

.subHeaderChildren {
  padding-top: 20px;

  & :global(.ui.mini.button) {
    font-size: .65em;
    line-height: 2.4em;
    min-height: 2.4em;
  }
}
