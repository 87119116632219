@import "../../../../styles/variables";

.resultListItem {
  background: $search-no-client-bg;
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  transition: background 200ms ease;

  &:hover {
    background: darken($search-no-client-bg, 5%);
    transition: background 200ms ease;
  }

  &.isClient {
    background: $search-client-bg;

    &:hover {
      background: darken($search-client-bg, 5%);
      transition: background 200ms ease;
    }
  }
}

.resultListImageAndName {
  align-items: center;
  display: flex;
  padding-bottom: 5px;
}

.resultListItemImage {
  border-radius: 50px;
  height: 39px;
  margin-right: 22px;
  position: relative;
  width: 39px;

  img {
    border-radius: 50px;
    height: 39px;
    width: 39px;
  }
}

.resultListItemTitle {
  color: #111111;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 30px;
}

.resultListItemOther {
  color: #404040;
  font-size: 13px;
  text-transform: uppercase;
  margin-right: 30px;
  min-width: 200px;
}

.highlight {
  background: #ffff99;
}