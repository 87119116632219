.columnsPage {
  display: flex;
  min-height: 100%;
}

.left {
  min-width: 247px;
  width: 247px;
}

.center {
  flex-grow: 1;
  padding: 20px 27px 0;
  max-width: calc(100% - 262px - 247px);
  width: calc(100% - 262px - 247px);
}

.right {
  min-width: 262px;
  width: 262px;
  padding-top: 20px;
  padding-right: 23px;
}
