@import "../../styles/variables.scss";

.gaugeChartWrapper {
  height: 130px;
  position: relative;

  &.small {
    height: 100px;
  }
}

.gaugeChartLabel {
  color: $font-grey;
  font-size: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;

  &.small {
  }
}

.gaugeChartValue {
  border-radius: 3px;
  color: $primary-color;
  font-size: 22px;
  font-weight: 600;
  height: 26px;
  line-height: 26px;
  margin: auto;
  text-align: center;
  text-transform: uppercase;

  span {
    font-size: 12px;
    font-weight: normal;
  }

  &.small {
    margin-bottom: 5px;
  }
}
