@import "../../styles/variables";

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  position: relative;
  padding-bottom: 20px;
}

.button {
  border: 0;
  border-radius: 3px;
  color: #fefefe;
  cursor: pointer;
  font-size: 12px;
  font-family: $font-family-montserrat;
  font-weight: 500;
  height: 31px;
  margin: 0;
  margin-left: 10px;
  text-transform: uppercase;
  transition: background 200ms ease;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    transition: background 200ms ease;
  }
}

.secondaryButton {
  @extend .button;

  background: #7699c0;

  &:hover {
    background: darken(#7699c0, 10);
  }
}

.primaryButton {
  @extend .button;

  background: #234270;

  &:hover {
    background: darken(#234270, 10);
  }
}