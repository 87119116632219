@import "../../../styles/variables.scss";

.timelineGroup {
  display: flex;
  margin: 10px 0;
}

.item {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  border-radius: 15px !important;
  width: 100%;
  position: relative;
  border-radius: 0.5px;
  transition: all 1s ease-in-out;
  background: $hover-grey;
  color: $font-grey;
  z-index: 1;
  padding: 10px;

  & + & {
    margin-top: 4px;
  }

  &.isNew {
    animation: Highlight 5s ease-in-out;
  }

  &.pending {
  }
}

.avatar {
  width: 32px;
  height: 32px;
  border: none;

  img {
    width: 32px;
    height: 32px;
  }
}

@keyframes Highlight {
  0%{
    background: #36417b;
    color: #ffffff;
    overflow: hidden;
    max-height: 0;
    z-index: 5;
  }
  5% {
    transform: scale(.9);
    max-height: 150px;
  }
  10%{
    overflow: auto;
    transform: scale(1.05);
  }
  13%{
    transform: scale(1);
  }
  80% {
    color: #ffffff;
    background: #144f74;
    max-height: none;
  }
  100%{
    background: #f8f8f8;
    color: #000000;
  }
}

.body {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.icon {
  background: $primary-color;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  margin-right: 15px;

  svg {
    fill: $white;
    width: 20px !important;
    height: 20px !important;
  }

  .withChildren &:after {
    content: '';
    position: absolute;
    left: 15px;
    top: calc(100% + 3px);
    transform: translate(-50%, 0);
    width: 3px;
    background-color: #a8b2cb;
    height: 100%;
  }
}

.info {
  flex: 1;
  padding-right: 55px;
}

.date {
  font-size: 10px;
  text-align: left;
  color: inherit;
  text-transform: uppercase;
}

.content {
  font-size: 13px;
  text-align: left;
  color: inherit;
  white-space: pre-line;
  text-transform: uppercase;
  font-weight: 600;
}

.avatar {
  font-size: 13.25px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
}

.inProcess {
  position: absolute;
  top: 50%;
  right: 65px;
  transform: translate(0, -50%);
  white-space: nowrap;
  background-color: $primary-color;
  height: 20px;
  line-height: 18px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  padding: 0 20px;
}

.subItems {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column-reverse;
}

.subItem {
  position: relative;
  overflow: hidden;
  padding: 5px 5px 5px 63px;
}

.subItemDot {
  position: absolute;
  top: 10px;
  left: 23px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 2px solid $primary-color;

  &:before {
    content: '';
    position: absolute;
    left: 3px;
    bottom: 100%;
    transform: translate(-50%, 0);
    width: 3px;
    background-color: $primary-color;
    height: 1000px;
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 100%;
    transform: translate(-50%, 0);
    width: 3px;
    background-color: $primary-color;
    height: 1000px;
    z-index: 1;

    .subItem:first-child & {
      display: none;
    }
  }
}

.subItemHour {
  position: absolute;
  top: -3px;
  left: 50%;
  transform: translate(-50%, 0) scale(0);
  white-space: nowrap;
  font-size: 10px;
  color: #fff;
  background-color: $primary-color;
  z-index: 2;
  border-radius: 3px;
  padding: 3px 4px 2px;
  line-height: 1;
  font-weight: 600;
  transition: transform .2s ease-in-out;

  .subItem:hover & {
    transform: translate(-50%, 0) scale(1);
  }
}

.subItemBody {
  white-space: pre-line;
  font-weight: 500;
  font-size: 12px;
  color: $font-grey;

  ol, ul {
    margin: 0;
    padding: 5px 0 10px 15px;
  }

  .subItem.disabled & {
    opacity: .3;
    transition: opacity ease-in-out .2s;
  }

  .subItem.disabled:hover & {
    opacity: 1;
  }
}

.subItemHeader {
  font-size: 13px;
  color: #444444;
  font-weight: 500;

  &.subItemHeaderCollapsible {
    cursor: pointer;
  }
  &.subItemHeaderCollapsed {}
}

.subItemHeaderIcon {
  vertical-align: middle;
  display: inline-block;
  margin-left: 10px;
  transition: transform .3s ease-in-out;

  svg {
    fill: #657294;
  }

  .collapsed & {
    transform: rotate(180deg);
  }
}

.subItemCollapsibleBody {
  opacity: 1;
  visibility: visible;
  transition: all .3s ease-in-out;

  &.collapsed {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
}

.subItemBodyComment {
  font-style: italic;
  padding: 10px;
  white-space: pre-line;
  background-color: rgba(255,255,255,.5);
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, .05)
}

.simulatorLink {
  display: inline-block;
  background: #274185;
  border-radius: 5px;
  cursor: pointer;
  color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  transition: background 200ms ease;

  &:hover {
    background: darken(#274185, 10);
    transition: background 200ms ease;
  }
}
