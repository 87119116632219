.selectionButtons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.selectionButton {
  align-items: flex-start;
  border: 1px solid #dddddd;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  font-weight: 700;
  height: 54px;
  justify-content: center;
  margin-bottom: 15px;
  margin-right: 15px;
  padding-left: 20px;
  padding-right: 30px;
  position: relative;
  min-width: 158px;

  &.disabled {
    background: #f6f6f6;
    color: #919191;
  }
}

.colorContainer {
  bottom: -1px;
  height: 6px;
  left: -1px;
  right: -1px;
  position: absolute;
}

.valueContainer {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
}

.checkboxContainer {
  fill: #b3b3b3;
  position: absolute;
  right: 5px;
  top: 5px;
  transition: fill 200ms ease;

  .selectionButton:not(&.disabled):hover  & {
    fill: #000000;
    transition: fill 200ms ease;
  }

  &.selected {
    fill: #000000;
  }
}

.sourceName {
  font-size: 11px;
  font-weight: 500;
}

.sourceValue {
  font-size: 12px;
  font-weight: 600;
}