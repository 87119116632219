@import "../../../styles/variables";

.resultListItem {
  align-items: center;
  background: $search-no-client-bg;
  cursor: pointer;
  display: flex;
  min-height: 45px;
  margin-bottom: 5px;
  padding-left: 17px;
  padding-right: 17px;

  &.isClient {
    background: $search-client-bg;
  }
}

.resultListItemImage {
  border-radius: 50px;
  height: 39px;
  margin-right: 22px;
  position: relative;
  width: 39px;

  img {
    border-radius: 50px;
    height: 39px;
    width: 39px;
  }
}

.resultListItemTitle {
  color: #111111;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 30px;
  min-width: 200px;
}

.resultListItemOther {
  color: #404040;
  font-size: 13px;
  text-transform: uppercase;
  margin-right: 30px;
  min-width: 200px;
}

.resultListItemType {
  align-items: center;
  border: 3px solid $search-no-client-main-alt;
  border-radius: 100px;
  color: $search-no-client-main-alt;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  margin-left: auto;
  text-transform: uppercase;
  width: 32px;

  .isClient & {
    border-color: $search-client-main-alt;
    color: $search-client-main-alt;
  }
}

.highlight {
  background: #ffff99;
}