.mainSection {
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  flex: 1;
  min-height: 106px;
  position: relative;

  .mainSectionSource {
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex: 1;
    min-height: 26px;
    z-index: 2;

    &:last-child {
      border-bottom: none;
    }
  }
}

.gain {
  top: -60px;
  color: #9b9b9b;
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  right: 5px;
}

.labels {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
}

.sourceTitle {
  padding-left: 12px;
  font-size: 12px;
}

.percentageBarsContainer {
  margin-left: 155px;
  left: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: calc(100% - 155px);
}