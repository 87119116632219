@import "../../styles/variables";

.cleanBarWrapper {
  align-items: center;
  background: $hover-grey;
  border-radius: 3px;
  color: $white;
  display: flex;
  font-size: 13px;
  justify-content: flex-start;
  min-height: 78px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.cleanBarWrapperHasError {
  background: $error-color;
}

.cleanBarWrapperCenter {
  justify-content: space-between;
}

:global {
  .cleanInputsBar {
    .field {
      align-items: center;
      clear: none !important;
      display: flex;
      margin: 0 !important;
      margin-left: 30px !important;
    }

    label {
      color: $font-grey !important;
      font-family: $font-family-montserrat !important;
      font-size: 13px !important;
      font-weight: normal !important;
    }

    input,
    .search.selection {
      @include withBorder;
      background: #fafafa !important;
      border: 0 !important;
      border-radius: 15px !important;
      font-family: $font-family-montserrat !important;
      font-weight: normal !important;
      height: 30px !important;
      line-height: 30px !important;
      min-height: 30px !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;

      .dropdown.icon {
        height: 30px !important;
        line-height: 30px !important;
        min-height: 30px !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    .selection.dropdown {
      width: 100% !important;
    }

    .loading.selection.dropdown > i.icon {
      padding: 0 !important;
    }
  }
}
