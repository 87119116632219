.customerProfileCtasBoxContainer {
  position: relative;
}

.pageOfusquer {
  background: #ffffff;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;

  animation: fadeInPageOfusquer ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 0.5 \9; /*just in case ie*/
}

@keyframes fadeInPageOfusquer {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}