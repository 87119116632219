:global {
  .notification-fade-enter {
    opacity: 0.01;
  }

  .notification-fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }

  .notification-fade-exit {
    opacity: 1;
  }

  .notification-fade-exit-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
  }
}