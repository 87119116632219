@import "../../styles/variables.scss";

label {
}

.wrapper {
  padding: 20px !important;

  & > * {
    font-family: Muli !important;
  }

  &:global(.modal.ui) {
    font-family: 'Muli' !important;
    @media only screen and (min-width: 767px) {
      width: 400px;
      padding: 10px;
      margin-top: 0 !important;
      margin-right: 10px;
    }

    &:global(.header) {
      background: none;
      padding: 0;
      color: $font-grey;
      font-weight: normal !important;
      font-size: 14px !important;
    }

    & > :global(.close) {
      display: none;
    }

    & > :global(.content) {
      padding: 0 !important;
    }

    & input {
      padding-left: 0 !important;
    }

    & > :global(.actions) {
      border-top: 0;
      margin: 1rem 0;
      padding: 0;

      & > :global(.button) {
        padding: 0;
        margin: 0;
      }
    }

    :global(.react-datepicker__input-container) {
        input {
          color: $font-grey !important;
        }
    }

    :global(.react-datepicker) {
      @include withShadows;
      @include rounded;
      border: none;

      :global(.react-datepicker__triangle) {
        border-bottom-color: $primary-color;

        &::before {
          border: none;
        }
      }

      :global(.react-datepicker__navigation--next) {
        border-left-color: #fff !important;
      }

      :global(.react-datepicker__navigation--previous) {
        border-right-color: #fff !important;
      }

      :global(.react-datepicker__header) {
        border: 1px solid $primary-color;
        font-family: Muli !important;
        background: $primary-color !important;
        color: white !important;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-color: $primary-color;

        :global(.react-datepicker__current-month) {
          color: white !important;
          text-transform: capitalize;
        }

        :global(.react-datepicker__day-name) {
          color: $white !important;
        }
      }

      :global(.react-datepicker__day) {
        color: $font-grey;

        &:hover {
          border-radius: 100%;
        }

        &:global(.react-datepicker__day--weekend) {
          color: transparentize($font-grey, 0.7);
        }

        &:global(.react-datepicker__day--outside-month) {
          color: transparentize($font-grey, 0.3);
        }
      }

      :global(.react-datepicker__day--today) {
        border-radius: 100%;
        background: $white;
        border: 1px solid $primary-color;
        color: $primary-color;
      }

      :global(.react-datepicker__day--selected) {
        border-radius: 100%;
        background: $primary-color;
        color: white;
      }
    }
  }

  :global(.ui.primary.button) {
    flex: 1;
    justify-content: center;
  }
  :global .ui.input input[type="text"],
  :global .ui.input input[type="date"],
  :global .ui.input input[type="time"],
  :global .ui.selection.dropdown,
  :global .react-datepicker__input-container input {
    border: 0 !important;
  }

  :global .field textarea {
    border-radius: 30px !important;
    height: 50px;
    align-items: center;
    border: none;
    font-family: Muli !important;
  }

  :global .ui.form label,
  :global .ui.form .field label {
    color: #224874;
    font-weight: 600;
  }

  :global(.field).date {
    flex: 1 !important;
  }
  :global(.field).time {
    flex: 1 !important;
  }

}

.datePicker {
  flex: 1;
}

.titleField, .customerField {
  border-bottom: 1px solid $hover-grey;
  padding-bottom: 5px;
  margin-bottom: 1rem;

  input {
    padding-left: 0;
  }
}

.labelTo {
  align-self: center;
}

.datesContainer {
  padding-left: 8px;
}
