@import '../../styles/variables.scss';

.header {
  padding: 20px;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 20px;
}

.headerNav {
  align-items: center;
  display: flex;
  position: relative;
  justify-content: space-between;
}

.headerLetter {
  align-items: center;
  border: 3px solid #ffffff;
  border-radius: 100px;
  color: #ffffff;
  display: flex;
  font-size: 28px;
  font-weight: bold;
  justify-content: center;
  height: 47px;
  width: 47px;
}

.headerIcon {
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  fill: $font-grey;
  height: 27px;
  justify-content: center;
  transition: opacity 200ms ease;
  width: 27px;

  &:hover {
    opacity: 0.8;
    transition: opacity 200ms ease;
  }
}

.headerDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 30px;
}

.title {
  font-size: 18px;
}

.subTitle {
  color: $font-grey;
  font-size: 14px;
}

.total {
  font-weight: 600;
  font-size: 16px;
  color: $font-grey;
}
