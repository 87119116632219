@import "../../styles/variables.scss";
@import "../../styles/animations/moveDown";

$avatarSize: 150px;

.avatarActions {
  font-size: 10px;
  position: relative;
  margin-bottom: 10px;
}

.img {
  background-color: $hover-grey;
  border-radius: 50%;
  display: block;
  height: $avatarSize;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: $avatarSize;
}

.noClientBelt {
  background: $primary-color;
  bottom: 0;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  height: 35px;
  left: 0;
  padding-top: 5px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  z-index: 10;
}

.uploadAvatar {
  align-items: center;
  background: $hover-grey;
  border-radius: 50%;
  color: $font-grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  height: $avatarSize;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: $avatarSize;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
  fill: $font-grey;

  .avatarActions:hover &, .noImage & {
    opacity: 1;
    visibility: visible;
  }
}

.uploadAvatarButtons {
  align-items: center;
  display: flex;
  margin-top: 10px;
}

.uploadAvatarButtonsFirst {
  border-right: 1px solid $font-grey;
  margin-right: 10px;
  padding-right: 10px;

  label {
    display: block;
    cursor: pointer;
    transition: fill 200ms ease;

    &:hover {
      fill: $primary-color !important;
      transition: fill 200ms ease;
    }
  }
}

.uploadAvatarButtonsSecond {
  display: block;
  cursor: pointer;
  transition: fill 200ms ease;

  &:hover {
    fill: #697ea4 !important;
    transition: fill 200ms ease;
  }
}

.uploadAvatarInput {
  position: fixed;
  top: -100em;
}

.cover {
  width: 150px;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.29);
  position: absolute;
  top: 0;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.action {
  position: absolute;
  bottom: .8em;
  left: 2.5em;
  width: 4.9em;
  height: 4.9em;
  background-image: linear-gradient(to top, transparentize(#83858e, .5), transparentize(#9eadb8, .5));
  border: solid .4em #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 2500ms linear;
  z-index: 4;

  &:hover {
    background-image: linear-gradient(to top, #83858e, #9eadb8);
  }

  svg {
    fill: #ffffff
  }
}

.actionFamily {
  background-image: linear-gradient(to top, transparentize(#36417b, .13), transparentize(#144f74, .13));
  left: auto;
  right: 3.3em;

  &:hover {
    background-image: linear-gradient(to top, #36417b, #144f74);
  }
}
