@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: 1;
  }
}

.leftCol {
  animation: loading 1s infinite;

  background-color: #f6f6f6;
  background-repeat: no-repeat;
  background-image:
          /* avatar */
          radial-gradient(circle 100px, white 99%, transparent 0),
          /* signature */
          linear-gradient(white 100px, transparent 0),
          /* name */
          linear-gradient(white 100px, transparent 0),
          /* data */
          linear-gradient(white 100px, transparent 0);

  background-size:
          200px 200px,  /* avatar */
          143px 118px, /* signature */
          150px 40px, /* name */
          160px 40px; /* data */

  background-position:
          center 40px,  /* avatar */
          center 250px, /* signature */
          center 370px, /* name */
          center 430px; /* data */
}

.centerCol {
  animation: loading 1s infinite;
  background-repeat: no-repeat;
  background-image:
          /* products */
          linear-gradient(#f6f6f6 100px, transparent 0),
          linear-gradient(#f6f6f6 100px, transparent 0),
          linear-gradient(#f6f6f6 100px, transparent 0),
          linear-gradient(#f6f6f6 100px, transparent 0),
          linear-gradient(#f6f6f6 100px, transparent 0),
          linear-gradient(#f6f6f6 100px, transparent 0),
            /* graphs */
          linear-gradient(#f6f6f6 130px, transparent 0),
          linear-gradient(#f6f6f6 130px, transparent 0),
            /* history */
          linear-gradient(#f6f6f6 300px, transparent 0);

  background-size:
          calc(50% - 32px) 100px,  /* products */
          calc(50% - 32px) 100px,  /* products */
          calc(50% - 32px) 100px,  /* products */
          calc(50% - 32px) 100px,  /* products */
          calc(50% - 32px) 100px,  /* products */
          calc(50% - 32px) 100px,  /* products */

          calc(50% - 32px) 130px,  /* graphs */
          calc(50% - 32px) 130px,  /* graphs */

          calc(100% - 54px) 300px; /* history */

  background-position:
          27px 50px,  /* products */
          calc(100% - 27px) 50px,  /* products */
          27px 160px,  /* products */
          calc(100% - 27px) 160px,  /* products */
          27px 270px,  /* products */
          calc(100% - 27px) 270px,  /* products */

          27px 420px,  /* graphs */
          calc(100% - 27px) 420px,  /* graphs */

          center 600px; /* history */
}

.rightCol {
  animation: loading 1s infinite;
  background-repeat: no-repeat;
  background-image:
          /* cta */
          linear-gradient(#f6f6f6 160px, transparent 0),
          linear-gradient(#f6f6f6 160px, transparent 0),
          linear-gradient(#f6f6f6 160px, transparent 0),
          linear-gradient(#f6f6f6 160px, transparent 0);

  background-size:
          calc(100% - 25px) 160px, /* cta */
          calc(100% - 25px) 160px, /* cta */
          calc(100% - 25px) 160px, /* cta */
          calc(100% - 25px) 160px; /* cta */

  background-position:
          0 50px,  /* cta */
          0 220px,  /* cta */
          0 390px,  /* cta */
          0 560px;  /* cta */
}

