.topRankingList {
  list-style: none;
  overflow: hidden;
  padding: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  50% {
    opacity: 0.9;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.infiniteScrollLoading {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.animateItems {
  li {
    animation: fadeIn ease 1;
    animation-fill-mode: forwards;
    animation-duration: 500ms;
    opacity: 0;
    opacity: 1 \9; /*just in case ie*/
  }

  li:nth-child(1) {
    animation-delay: 1ms;
  }

  li:nth-child(2) {
    animation-delay: 200ms;
  }

  li:nth-child(3) {
    animation-delay: 300ms;
  }

  li:nth-child(4) {
    animation-delay: 400ms;
  }

  li:nth-child(5) {
    animation-delay: 500ms;
  }

  li:nth-child(6) {
    animation-delay: 600ms;
  }

  li:nth-child(7) {
    animation-delay: 700ms;
  }

  li:nth-child(8) {
    animation-delay: 800ms;
  }

  li:nth-child(9) {
    animation-delay: 900ms;
  }

  li:nth-child(10) {
    animation-delay: 1000ms;
  }

  li:nth-child(11) {
    animation-delay: 1050ms;
  }

  li:nth-child(12) {
    animation-delay: 1100ms;
  }
}