
$bg: #f3f5f9;
$mask-bg: #2b2b2b;
$arrow-bg: $mask-bg;

.gauge {
  position: relative;
  font-size: 10px;
  width: 20em;
  height: 10em;
}

// Gauge
.mask {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  display: block;
  width: 20em;
  height: 10em;
}

.semiCircle {
  position: relative;

  display: block;
  width: 20em;
  height: 10em;


  border-radius: 50% 50% 50% 50% / 100% 100% 0% 0% ;

  &::before {
    content: "";

    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;

    display: block;
    width: 11em;
    height: 5.5em;
    margin-left: -5.5em;

    background: $bg;

    border-radius: 50% 50% 50% 50% / 100% 100% 0% 0% ;
  }
}

.semiCircleMask {
  position: absolute;
  top: 0;
  left: 0;

  width: 20em;
  height: 20em;

  background: transparent;

  transform: rotate(0deg);
  transform-origin: center center;
  backface-visibility: hidden;
  transition: transform .3s ease-in-out;

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    display: block;
    width: 20em;
    height: 10em;
    margin: 0;

    background: $mask-bg;

    border-radius: 50% 50% 50% 50% / 100% 100% 0% 0% ;
  }
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;

  width: 20em;
  height: 20em;
  z-index: 3;
  transition: all .3s ease-in-out;
}

.arrowInner {
  position: absolute;
  top: 0;
  left: 0;

  width: 20em;
  height: 20em;
  transform: rotate(-90deg);

  &::before {
    font-size: 2.5em;

    content: "";

    position: absolute;
    top: 50%;
    margin-top: -3em;
    left: 50%;
    margin-left: -.3em;
    z-index: 4;

    border: .3em solid transparent;
    border-bottom: 3em solid $arrow-bg;
    border-top: 0;
  }

  &::after {
    font-size: 2.5em;
    content: "";

    position: absolute;
    top: 50%;
    margin-top: -.3em;
    left: 50%;
    margin-left: -.3em;
    z-index: 4;

    width: .6em;
    height: .6em;
    border-radius: 50%;
    background-color: $arrow-bg;
  }
}
