@import "../../styles/variables.scss";

.button {
  align-items: center;
  background: $hover-grey;
  border-radius: 15px;
  color: $font-grey;
  cursor: pointer;
  display: flex;
  flex: 1;
  padding: 1rem 1.5rem;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  margin-right: 5px;
  text-transform: uppercase;
  transition: opacity 200ms ease;
  @include withBorder;

  &:hover:not(.active) {
    opacity: 0.8;
    transition: opacity 200ms ease;
  }

  svg {
    fill: $font-grey;
  }

  &.active {
    background: $white;

    svg {
      fill: $primary-color;
    }
  }
}
