.installmentPresetContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.installmentPreset {
  border-radius: 3px;
  box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 75px;
  padding-left: 14px;
  padding-right: 14px;
  width: 148px;
  transition: background 200ms ease;

  &:not(.selected) {
    background: rgb(68,72,100); /* Old browsers */
    background: linear-gradient(to top, rgb(68,72,100) 0%,rgb(54,58,88) 100%);
  }

  &.selected {
    background: rgb(68,72,100); /* Old browsers */
    background: linear-gradient(to top, rgb(135,143,196) 0%,rgb(103,112,173) 100%);

    &.activeSpecialRate {
      background: rgb(151,155,229); /* Old browsers */
      background: linear-gradient(to top, rgb(151,155,229) 0%,rgb(111,123,202) 100%);
    }
  }
}

.installmentPresetTitle {
  color: #ffffff;
  font-size: 13px;
  text-transform: uppercase;
}

.installmentPresetData {
  color: #b6b9ce;
  display: flex;
  font-size: 12px;
  font-weight: 300;
  justify-content: space-between;
  transition: color 200ms ease;

  .selected & {
    color: #ffffff;
  }
}