.spaRightSectionWrapper {
  background-color: #ffffff;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 15px;
  height: 100%;
  padding-bottom: 20px;
  position: relative;
  overflow: auto;
}
