@import "../../styles/variables.scss";

$loginInputHeight: 42px;

.loginButton {
  border-radius: 100px;
  border: none;
  background: $login-button;
  color: #ffffff;
  cursor: pointer;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2;
  margin-bottom: 32px;
  height: 42px;
  line-height: 40px;
  margin-top: 15px;
  transition: background 300ms ease;
  outline: none;
  position: relative;
  width: 184px;

  &:hover,
  &:focus {
    background: $login-button, 0;
    transition: background 300ms ease;
  }

  div {
    top: 50% !important;
    left: 50% !important;
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
  }
}
