@import '../../styles/variables.scss';

.expandableSearch {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #686767;
  font-weight: 400;
  cursor: pointer;

  svg {
    fill: #31c2d3;
    margin-left: 10px;
  }
}

.container {
  flex: 1;
  overflow: hidden;
  position: relative;
  text-align: right;

  a, a:hover {
    color: inherit;
  }
}

.flyingInput {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: -80%;
  transition: all 200ms ease-in-out;
  background: #ffffff;
  border: 0;
  border-bottom: #000000 solid 1px;
  opacity: 0;
  visibility: hidden;

  &:focus {
    outline: none;
  }

  &.isVisible {
    opacity: 1;
    right: 0;
    visibility: visible;
  }
}
