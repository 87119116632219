.groupInputsWrapper {
  align-items: center;
  display: flex;
}

.fieldType {
  flex: 1;
  margin-bottom: 15px !important;
  margin-left: 15px !important;
  width: 100%;
}

.nodeLabel {
  color: #545454;
  font-weight: 500;
}

.error {
  color: #eb1a3c;
}