.wrapper {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 30px;
  position: relative;
}

.title {
  color: #353535;
  font-size: 15px;
  font-weight: 600;
  padding-right: 40px;
}

.datesWrapper {
  color: #617c9a;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 5px;
  text-transform: capitalize;
}

.desc {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-line;
  overflow: auto;
  max-height: 54vh;
}

.eventIcon {
  fill: #657d9b;
  position: absolute;
  right: 0;
  top: 0;
}
