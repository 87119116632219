@import './variables.scss';

:global(.modal.ui) {

  @media only screen and (min-width: 1200px) {
    width: 750px;
  }

  :global(.header) {
    background: white !important;
    color: $font-grey;
  }

  &:global(.page.modals.dimmer.visible.active) {
    background-color: white !important;
  }

  & > :global(.content) {
    background-color: #f1f4f7;
  }
  & > :global(.actions) {
    background-color: #f1f4f7;
    border-top: 0;
  }
  & > :global(.close) {
    color: $font-grey;
  }
}

:global .ui.input input[type="text"],
:global .ui.input input[type="date"],
:global .ui.input input[type="time"],
:global .ui.input input[type="number"],
:global .ui.selection.dropdown {
  border: 0;
}

:global .field textarea {
  border: 0 !important;
}

:global .ui.form label,
:global .ui.form .field label {
  color: $primary-color;
  font-weight: 600;
}

:global(.field).date {
  width: 28% !important;
}

:global(.field).time {
  width: 22% !important;
}

:global .error .ui.selection .default.text {
  color: #fbe6ea !important;
}

//MODALS
:global(.ui.modals.dimmer) {
  background: rgba(256, 256, 256, 0.4) ;
}

:global(.ui.modal) {
  border-radius: 15px;

  & > :global(.content) {
    background-color: white !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  & > :global(.actions) {
    background-color: white !important;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

//  BUTTONS
:global(.ui.button) {
  font-family: 'Muli' !important;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 300 !important;
}
:global(.ui.primary.button) {
  transition: all 0.3s ease;
  background-color: $primary-color;

  &:hover {
    background: transparentize($primary-color, 0.6);
  }
}

:global(.ui.secondary.button) {
  transition: all 0.3s ease;
  background-color: $font-grey;

  &:hover {
    background: transparentize($font-grey, 0.6);
  }
}

// INPUTS
:global(.dropdown.selection.ui) {
  background: $hover-grey !important;
  border-radius: 0 !important;
  color: $font-grey;

  :global(.menu) {
    border: none !important;
  }
}

:global(.checked.checkbox.ui) {
  label:before {
    border-color: $primary-color !important;
  }

  label:after {
    color: $primary-color !important;
  }

  &:global(.radio) {
    label:after {
      background-color: $primary-color !important;
    }
  }
}

input[type=number] {
  border-bottom: 1px solid $hover-grey !important;
}



//MATERIALUI FORMS
:global(input) {
  font-family: Muli !important;
  color: $font-grey !important;
  font-weight: light !important;
  background: none !important;
  border: none !important;
}

:global(label) {
  font-weight: normal !important;
  font-family: 'Muli' !important;
}
