.accessReasonsModal {
  &:global(.modal.ui) {
    width: 652px !important;
    border-radius: 15px !important;

    :global(.content) {
      border-radius: 15px !important;
    }
  }
}
