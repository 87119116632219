@import "../../styles/variables.scss";

.wrapper {
  min-height: 100vh;
  margin: auto;
  width: 100%;

  :global(.ui.form .field > label) {
    font-weight: bold;
  }

  :global(.ui.circular.labels .label) {
    &:hover {
      color: inherit;
      cursor: initial;
    }
  }
}

.head {
  height: $main-navbar-height;
}

.body {
  display: flex;
  min-height: calc(100vh - #{$main-navbar-height});

}

.sidebar {
  flex-basis: $secondary-navbar-width;
  min-width: $secondary-navbar-width;
}

.content {
  flex-grow: 1;
  max-width: calc(100% - 63px); /* 63px width left menu */
}
