.wrapper {
  &:global(.ui.icon.message) {
    max-width: 100%;

    &.centered {
      transform: translate(-50%, -50%);
      position: relative;
      top: 50%;
      left: 50%;
      width: 500px;
    }
  }
}

.actions {
  padding-top: 5px;
}
