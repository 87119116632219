@import "../../styles/variables.scss";
$img-size: 45px;

.wrapper {
  position: relative;
}

.customerImageWrapper {
  align-items: center;
  display: flex;
  padding-bottom: 20px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 20px;
}

.customerImage {
  background: $hover-grey;
  border-radius: 100px;
  height: $img-size;
  width: $img-size;
  margin-right: 15px;
  overflow: hidden;
  position: relative;

  img {
    height: $img-size;
    width: $img-size;
  }
}

.customerName {
  color: #353535;
  font-size: 16px;
  text-transform: capitalize;

  a, a:hover {
    color: inherit;
  }
}

.ctaName {
  align-items: center;
  border-bottom: 1px solid #dfe5ef;
  color: #353535;
  display: flex;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 7px;
  justify-content: space-between;
  padding-bottom: 7px;

  &:hover {
    color: #000000;
  }

  &.ctaNameBold {
    font-weight: bold !important;
  }

  &.ctaNameCompleted {
    color: #c5c5c5  ;
  }
}

.ctaNameIconContainer {
  align-items: center;
  background: #ced5e5;
  border-radius: 50px;
  display: flex;
  fill: #76798b;
  justify-content: center;
  height: 26px;
  transition: background 200ms ease, fill 200ms ease;
  width: 26px;

  &:not(.ctaNameIconContainerCompleted) {
    .ctaName:hover & {
      background: #76798b;
      fill: #ced5e5;
      transition: background 200ms ease, fill 200ms ease;
    }
  }

  &.ctaNameIconContainerCompleted {
    background: #efefef;
    fill: #c5c5c5;
  }
}

.ctasContainer {
  list-style: none;
  padding: 0;
  padding-left: 25px;
  padding-right: 25px;
}
