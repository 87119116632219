.formAppeals {
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}

.modalHeader {
  background-color: #002A8D;
}

.modalContent {
  min-height: 150px;
}

.formRow {
  display: flex;
  margin-top: 1rem;
  margin-left: 20px;
  margin-right: 20px;

  .formInput {
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.formButtonsContainer {
  display: flex;
  justify-content: right;
  margin-top: 20px;

  .formButton {
    margin-left: 20px;
    //border-radius: 50%;
  }
}

.successfulModal {
  margin-top: 10px;

  .paragraph {

  }
}