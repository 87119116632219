@import "../../styles/variables.scss";

.resultsGraphWrapper {
  height: 250px;
  margin-top: 100px;
  padding-right: 10px;
  position: relative;
}

.resultsGraph {
  border-bottom: 1px solid #ced4e0;
  display: flex;
  justify-content: space-around;
  margin-left: 40px;
  margin-right: 50px;
  position: relative;
}

.zeroPercentage,
.oneHundredPercentage {
  color: $font-grey;
  font-size: 10px;
  left: -45px;
  position: absolute;
  text-align: right;
  width: 38px;
}

.zeroPercentage {
  bottom: -10px;
}

.oneHundredPercentage {
  top: -10px;
}

@keyframes dailyGoalAnimate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
