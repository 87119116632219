@import "../../../../styles/variables";

.inputs {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
  z-index: 1;

  & > input {
    border-bottom: 1px solid $hover-grey;
  }

}

.apply {
  font-size: 12px;
  color: $primary-color;
  text-decoration: underline;
  display: inline-block;
  margin-top: -10px;
  padding: 5px 3px;
  position: relative;
  z-index: 2;
}
