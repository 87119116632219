@import "../../../../styles/variables";

.inputs {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.apply {
  font-size: 12px;
  text-transform: uppercase;
  color: $ugly-blue;
  font-weight: 600;
  text-decoration: underline;
  display: inline-block;
  margin-top: 0;
  padding: 5px 3px;
}

.help {
  color: #888;
  font-size: 9px;
  padding-top: 5px;
  padding-left: 120px;
}