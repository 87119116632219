@import "./variables";

* {
  box-sizing: border-box;
}

body {
  background-color: $background;
  font-family: $default-font !important;
  display: flex;
  flex: 1;
  min-width: 276px !important;
}

.cantAvoidThisDiv {
  display: flex;
  flex: 1;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height: 1;
  font-family: $default-font;
  font-weight: normal;
}

input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="date"]::-webkit-clear-button {
  display: none; /* Hide the button */
  -webkit-appearance: none; /* turn off default browser styling */
}

:global {
  input.error {
    background: #fafafa;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fbe6ea !important;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #fbe6ea !important;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #fbe6ea !important;
    }
  }
}

::selection {
  background: $primary-color;
  color: white;
}
