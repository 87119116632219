@import "../../../styles/variables";

.pillTitle {
  background: $search-no-client-main-alt;
  border-radius: 25px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 18px;
  text-transform: uppercase;
  text-align: center;
  width: 120px;

  &.isClient {
    background: $search-client-main-alt;
  }
}