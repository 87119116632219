@import "../../styles/variables.scss";

.resultsGraphWrapper {
  height: 180px;
  margin-top: 70px;
  padding-right: 10px;
  position: relative;
  flex: 1;
}

.resultsGraph {
  border-bottom: 1px solid #ced4e0;
  display: flex;
  justify-content: space-around;
  margin-left: 40px;
  margin-right: 10px;
  position: relative;
}

.bestWorstIndicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 53px;
  margin-right: 26px;
  position: relative;
}
.best,
.worst {
  font-size: 9px;
  color: $font-grey;
  text-transform: uppercase;
}

.bestWorstLine {
  background: linear-gradient(90deg, #49b749 0%, #f04e4d 100%); /* w3c */;
  bottom: -5px;
  left: 0;
  height: 2px;
  line-height: 2px;
  font-size: 0;
  position: absolute;
  width: 100%
}
