@import "../../../../../styles/variables";

.graph {
  background-color: #ffffff;
  height: 220px;
  margin-bottom: 20px;
  font-size: 11px;
}

.showMore {
  display: block;
  text-align: center;

  svg {
    fill: $dark-slate-blue
  }
}

.trClickable {
  background: transparent;
  cursor: pointer;
  transition: background 300ms ease;

  &:hover {
    background: #d5ecfb;
    transition: background 300ms ease;
  }
}

/*.resumesIcon {
  cursor: pointer;
  fill: $dusk-blue;
}*/

.tableAmountOutcome {
  color: $dusty-orange !important;
}

.allDataContainer {
  align-items: center;
  display: flex;
}

.allDataLink {
  align-items: center;
  display: flex;
  margin-right: 10px;
}
