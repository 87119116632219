@import "../../styles/variables.scss";

.resultsGraphLabels {
  bottom: 0;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
  width: 100%;
}

.resultsGraphLabel {
  color: $font-grey;
  align-items: center;
  display: flex;
  font-size: 10px;
}

.resultsGraphLabelDot {
  border-radius: 100px;
  display: block;
  font-size: 1px;
  height: 8px;
  line-height: 5px;
  margin-left: 20px;
  margin-right: 10px;
  width: 8px;

  &.criticalGoal {
    background: $error-color;
  }

  &.aboveGoal {
    background: $warning-yellow;
  }

  &.overTheTopGoal {
    background: $success-color;
  }

  &.belowGoal {
    background: $warning-orange;
  }
}
