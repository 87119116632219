@import "../../../styles/variables";

.noResults {
  align-items: center;
  background: $search-no-client-bg;
  display: flex;
  justify-content: center;
  min-height: 150px;

  &.isClient {
    background: $search-client-bg;
  }
}

.createNewClient {
  color: #23426e;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}