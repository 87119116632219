@import "../../styles/variables";
@import "../../styles/animations/bounce";

.box {
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding: 20px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  transition: left 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &.completed {
    cursor: inherit;
  }
}

.bodyTitle {
  color: $font-grey;
  font-size: 14px;
  line-height: 1.26;
  z-index: 4;
}
