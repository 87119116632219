.wrapper {
  &:global(.modal.ui) {
    @media only screen and (min-width: 1200px) {
      width: 650px;
    }

    & > :global(.content) {
      min-height: 200px;
    }
  }
}