@import "../../../styles/variables";

.wrapper {
  position: relative;

  :global(.dropdown.selection.ui) {
    border: none !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }

  :global(.menu) {
    border: none !important;
  }
}

.top {
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 12px 12px 12px 35px;
}

.formGroup {
  align-items: center;
  display: flex;
  margin-top: 20px;

  label {
    font-size: 14px;
    color: $font-grey;
    margin-right: 35px;
  }

  .formControl {
    border-bottom: 1px solid $hover-grey;

    :global(.input.fluid.ui) {
      input {
        flex: 1;
        padding-left: 0 !important;
        width: 450px !important;
      }
    }

  }

  &:first-child {
    margin-top: 0;
  }
}

.formGroupCategories {
  width: 350px;
}

.formGroupDays {
  width: 120px;
}

.formGroupLabel {
  width: 187px;
}

.filters {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 700;
  color: $font-grey;
  margin-bottom: 0;
}

.appliedFilters {
  padding: 0 0 0 10px;
  font-size: 14px;
  color: $dusk-blue;
  margin-bottom: 0;
}

.appliedFilter {
  color: $primary-color;
  display: inline-block;

  & + & {
    &:before {
      content: "/";
      display: inline-block;
      padding: 0 5px;
    }
  }
}

.bottom {
  position: relative;
  background-color: white;
  border-radius: 15px;
  min-height: 400px;
}

.table {
  z-index: 1;
}


.table tr th {
  font-weight: 600;
  padding: 10px 15px;
  background: $primary-color;
  color: $white
}

.table tr td {
  min-height: 30px;
  padding: 6px 10px;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color .3s ease-in-out;

  :global(.checkbox.ui) {
    label:before {
      border-color: $hover-grey;
    }
  }

  :global(.checked.checkbox.ui) {
    label:before {
      border-color: $primary-color;
    }

    label:after {
      color: $primary-color;
    }
  }
}

.table tr:hover td {
  background-color: $hover-grey;
}

.table tr.rowDisabled td {
  opacity: .5;
  background-color: #f6f6f6;
}

.table tr.rowDisabled:hover td {
  opacity: 1;
}

.cellCheck {
  text-align: center !important;

  & checkbox:before {
    border-color: red;
  }
}

.table .tableActionCell {
  text-align: right;

  svg {
    fill: #96a3ba
  }
}

.actionsBarSticky {
  z-index: 2;
  position: relative;
}

.actionsBar {
  display: flex;
  @include withBorder;
  background-color: $hover-grey;
  align-items: center;
  padding-left: 20px;
}

.actionsBarFilters {
  display: block;
  padding: 10px 0;
}

.actionsBarButtons {
  margin-left: auto;
  display: flex;
  padding: 15px 10px;
}

.required {
  color: #ff0000;
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;
  text-align: center;
  padding: 0 50px;
  white-space: pre;
}

.fullName {
  text-transform: capitalize;
}

.identityTypeDescription {
  white-space: nowrap;
  text-transform: uppercase;
}

.identityNumber {
  font-weight: bold;
}
