@import "../../../styles/variables.scss";

.title {
  color: $font-grey;
  font-size: 12px;
  font-weight: bold;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.mainLoading {
  flex: 1;
  position: relative;
}

.grid {
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;

  &.displayAll {
    padding: 0;
    grid-template-columns: repeat(4, 1fr);
  }

  .name {
    color: $font-grey;
    font-size: 12px;
    margin-bottom: 8px;

    .branchIcon {
      fill:  #537c9b;
      vertical-align: text-bottom;
      margin-right: 10px;
    }
  }

  .box {
    @include withBorder;
    @include rounded;
    background-color: $white;
    padding: 1rem;

    .line {
      align-items: center;
      justify-content: flex-start;
      display: flex;
      height: 33px;
    }

    .served {
      color:  $primary-color;
      font-weight: 600;

      .icon {
        fill: $font-grey;
      }
    }

    .separator {
      height: 1px;
      width: 85%;
      margin: 1px auto;
      background-color: #ffffff;
    }

    .waiting {
      color:  $font-grey;

      .icon {
        fill: $font-grey;
      }
    }

    .inactive {
      opacity: 0.7;
    }

    .iconCell {
      flex: 2;
      padding: 2px 15px;
    }

    .number {
      flex: 1;
      font-size: 24px;
      padding: 2px 6px;
      text-align: center;
    }

    .category {
      flex: 4;
      padding: 2px 6px;
      position: relative;

      &.alive {
        &::after {
          animation: heartbeat 2.5s infinite; /* IE 10+, Fx 29+ */
          background: $primary-color;
          border-radius: 100px;
          content: ' ';
          height: 7px;
          position: absolute;
          right: 17px;
          top: 8px;
          width: 7px;
        }
      }
    }
  }
}

@keyframes heartbeat {
  0%   {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.3)
  }
  100% {
    opacity: 0;
    transform: scale(1)
  }
}


.greeterOffline {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-top: 20px;
}

.greeterStatus {
  align-items: center;
  color: $font-grey;
  display: flex;
  fill: $font-grey;
  font-size: 11px;
  justify-content: flex-end;
  margin-left: auto;

  svg {
    margin-right: 5px;
  }
}

.greeterLoading {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex: 1 ;
  padding-left: 25px;
  position: relative;
}
