@import "../../styles/variables";


.button {
  align-items: center;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: $font-family-montserrat;
  font-size: 11px;
  font-weight: 600;
  height: 33px;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  transition: background-color 200ms ease;
  width: 100%;

  &:hover,
  &:focus {
    transition: background-color 200ms ease;
  }

  & + & {
    margin-top: 10px;
  }

  &.primary {
    background: $primary-color;
    transition: color 0.4s ease;

    &:hover,
    &:focus {
      transition: color 0.4s ease;
      background: $primary-dark;
    }
  }

  &.secondary {
    background: #7699c0;

    &:hover,
    &:focus {
      background: darken(#7699c0, 10);
    }
  }
}
