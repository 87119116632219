@import "../../styles/variables";

.pageContent {
  display: flex;
  align-items: stretch;
}

.pageSidebar {
  width: 226px;
  margin-right: 25px;
}

.body {
  flex: 1;
  padding-top: 30px;
  padding-bottom: 30px;

  :global(.ui.dimmer) {
    z-index: 999;
  }
}

.header {
  display: flex;
}

.bodyTop {
  //margin-bottom: 15px;
  position: relative;
  padding: 10px 10px 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.counters {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem ;
}

.counter{
  background: white;
  @include withBorder;
  border-radius: 15px;
  padding: 2rem 1rem;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  margin-right: 1.5rem;
  min-height: 8rem;

  &:last-child {
    margin-right: 0;
  }
}

.countersLeftTotal {
  font-size: 18px;
  font-weight: bold;
  color: $font-grey;
  margin-bottom: 0;
  white-space: nowrap;
}

.countersLeftCurrent {
  font-size: 35px;
  font-weight: bold;
  color: $primary-color;
}

.counterLabel {
  color: $font-grey;
  font-size: 14px;
  margin-bottom: 10px;
}

.countersAmountBox {
  padding: 0 50px 0 50px;
}

.counterAmount {
  font-size: 24px;
  font-weight: 600;
  color: $font-grey;
  white-space: nowrap;
}

.countersAmountTitle {
  font-size: 11px;
  font-weight: 600;
  color: $greyish;
  text-transform: uppercase;
}

.createButtonBox {
  align-items: center;
}

.createButton {
  display: flex !important;
  align-items: center;
  background: transparent !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  white-space: nowrap;
  text-transform: none !important;
  color: $font-grey !important;
  font-size: 14px !important;

  button {
    margin-left: 10px;
  }

  &:hover {
    color: $primary-color !important;
  }
}

.bodyList {
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.action {
  color: $primary-color;
  font-size: 12px;
  text-decoration: underline;
  transition: color 200ms ease;
  margin: 0 15px;

  &:hover {
    color: $font-grey;
    text-decoration: underline;
    transition: color 200ms ease;
  }
}

.actionsLabel {
  color: #243c69;
  font-size: 11px;
  text-transform: uppercase;
}

.actionsButtons {
  display: flex;
  align-items: center;
}
