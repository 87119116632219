@import "../../styles/variables.scss";

.link {
  vertical-align: sub;
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;

  svg {
    fill: $primary-color;
  }
}
