@import "../../../styles/animations/fadein";

.fadeIn {
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
}

.wrapper {
  background-color: #e5eaf3;
  margin-bottom: 5px;
  @extend .fadeIn;
}

.placeholder {
  font-size: 11px;
  font-weight: bold;
  color: #7699c0;
  text-transform: uppercase;
  padding: 7px 20px;
}

.selected {
  background-color: transparentize(#fefefe, .62);
  color: #243e6a;
  font-size: 12px;
  padding: 5px 20px;
}

.selectedLabel {
  font-weight: bold;
}

.options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.option {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #243e6a;
  border-top: #fff solid 1px;
  font-weight: 600;

  a {
    color: inherit;
    display: block;
    padding: 7px 20px;
    transition: background-color .25s ease-in-out;

    &:hover, &:focus {
      background-color: rgba(0, 0, 0, .03);
    }
  }
}

/* */