@import "../../../../styles/variables";

.familySidebar {
  @include withBorder;
  @include withShadows;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparentize($hover-grey, .02);
  z-index: 2;
  padding: 200px 0;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-in-out;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.members {
  position: relative;
  top: 100px;
  opacity: 0;
  transition: all .2s ease-in-out;

  .visible & {
    opacity: 1;
    top: 0;
  }
}

.membersTitle {
  color: white;
  background-color: $primary-color;
  line-height: 1.9;
  padding: 0 14px;
}
