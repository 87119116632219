.totalDisplay {
  align-items: center;
  background: #878fc4;
  border-radius: 3px;
  color: #ffffff;
  display: flex;
  font-size: 17px;
  font-weight: bold;
  height: 27px;
  justify-content: space-between;
  margin-top: 15px;
  text-transform: uppercase;
  transition: background 200ms ease;
  padding-left: 12px;
  padding-right: 12px;

  &.activeSpecialRate {
    background: #8492ef;
    transition: background 200ms ease;
  }
}

.number {
  font-size: 20px;
  font-weight: 500;
}

@keyframes showAnimatedTotal {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fadeIn {
  animation: showAnimatedTotal ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
}

.animatedTotal {
  @extend .fadeIn;
  animation-delay: 4500ms;
}