@import '../../styles/variables.scss';

.ctaBoxWrapper {
  background: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  transition: all 0.6s ease;
  @include withBorder;
}

.ctaCategoryGroupDetailBoxWrapper {
  background-color: #e7ecf4;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 5px;
  height: 100%;
  padding-bottom: 20px;
}

.ctaCategoryHeader {
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 70px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  .ctaCategoryHeaderLetter {
    align-items: center;
    border: 3px solid #ffffff;
    border-radius: 100px;
    color: #ffffff;
    display: flex;
    font-size: 28px;
    font-weight: bold;
    justify-content: center;
    height: 47px;
    width: 47px;
  }

  .ctaCategoryHeaderIcon {
    align-items: center;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    fill: #ffffff;
    height: 27px;
    justify-content: center;
    margin-left: 10px;
    transition: opacity 200ms ease;
    width: 27px;

    &.ctaCategoryHeaderMarginLeft {
      margin-left: auto;
    }

    &:hover {
      opacity: 0.8;
      transition: opacity 200ms ease;
    }
  }
}

.ctaCategoryTitle {
  color: #3a3a3a;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 100px;
  text-transform: uppercase;
  padding-left: 20px;
  position: relative;

  span {
    font-size: 32px;
  }
}

.ctaCategoryGroup {
  align-items: center;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
  display: flex;
  height: 70px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;

  &:hover {
    background: $hover-grey;
    transition: background 0.2s ease;

    .ctaCategoryGroupIcon {
      opacity: 1;
      transition: opacity 600ms ease;
    }
  }

  .ctaCategoryGroupName {
    color: #2e2e2e;
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    border-left: 3px solid black;
    padding-left: 10px;
    min-height: 40px;
    line-height: 2.6;
  }

  .ctaCategoryGroupTotal {
    width: 55px;
    display: flex;
    height: 55px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    margin-right: 1rem;
  }

  .ctaCategoryGroupIcon {
    align-items: center;
    opacity: 0;
    display: flex;
    fill: $font-grey;
    justify-content: center;
    height: 30px;
    margin-left: 20px;
    transition: opacity 200ms ease;
    width: 30px;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.ctaCategories {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.ctaCategoryBox {
  cursor: pointer;
  display: flex;
  flex: 1 0 calc(33.333% - 20px);
  font-size: 14px;
  font-weight: 300;
  height: 24px;
  line-height: 22px;
  margin-bottom: 11px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;

  .ctaCategoryBoxTotal {
    border-radius: 8px;
    color: #fff;
    text-align: center;
    min-width: 45px;
    padding: 0 5px;
  }

  .ctaCategoryBoxText {
    color: $font-grey;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
  }
}

.sectionTitleWrapper {
  align-items: center;
  border-bottom: 1px solid $another-grey;
  display: flex;
  padding: 20px 0 10px 0;
  margin: 0 20px;
}

.sectionTitle {
  display: flex;
  flex: 1;
  color: $font-grey;
  font-size: 16px;
}

.sectionCositoCta {
  color: $font-grey;
  font-size: 14px;
  text-transform: uppercase;
}

.ctaTopTenItem {
  align-items: center;
  color: $font-grey;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px 20px;
  position: relative;
  transition: background 0.4s ease;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  &:hover {
    background: $hover-grey;
  }

  &.ctaTopTenItemCompleted {
    background: $hover-grey;
    border-right: 1px solid $success-color;

    & * {
      opacity: 0.5;
    }
  }

  .ctaTopTenItemPhoto {
    position: relative;
    height: 55px;
    width: 55px;
    margin-right: 1rem;

    img {
      border-radius: 50px;
      float: left;
      width: 55px;
    }

    div {
      background: #eaeaea;
      border: 3px solid black;
      border-radius: 50px;
      height: 55px !important;
      width: 55px !important;
    }
  }

  .ctaTopTenItemDetails{
    display: flex;
    flex: 1;
    justify-content: flex-start;
    flex-direction: column;
    border-left: 3px solid #000;
    padding-left: 10px;
  }

  .ctaTopTenCustomerName {
    font-weight: bold;
    text-transform: capitalize;
  }

  .ctaTopTenCtaName {
    font-weight: 300;
  }

  .ctaTopTenCtaCount {
    font-weight: bold;
    text-align: right;
    width: 10%;
  }

  .ctaTopTenCtaArrow {
    display: flex;
    justify-content: flex-end;
    width: 10%;

    .ctaTopTenCtaArrowBtn {
      align-items: center;
      display: flex;
      background: #818ea3;
      border-radius: 50px;
      fill: #ffffff;
      height: 30px;
      justify-content: center;
      transition: background 200ms ease, fill 200ms ease;
      width: 30px;

      &.inverted {
        background: #ced5e5;
        fill: #626477;
      }
    }
  }

  &:hover {
    .ctaTopTenCtaArrow {
      .ctaTopTenCtaArrowBtn {
        background: #ced5e5;
        fill: #626477;
        transition: background 200ms ease, fill 200ms ease;

        &.inverted {
          background: #818ea3;
          fill: #ffffff;
          transition: background 200ms ease, fill 200ms ease;
        }
      }
    }
  }
}

.categoryGroupName {
  align-items: center;
  color: #2e2e2e;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;
  position: relative;

  span {
    display: block;
    font-weight: bold;
    text-align: center;
    width: 30px;
  }
}

.topTenEmpty {
  display: flex;
  color: #ffffff;
  font-weight: 600;
  flex: 1;
  justify-content: center;
  padding-top: 70px;
  position: relative;
  text-transform: uppercase;

  &.topTenEmptyInverted {
    color: #000000;
  }
}

.categoryGroupFilters {
  background: #e9ebf0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  padding: 15px;
  position: relative;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;

  .filtersText {
    font-weight: normal !important;
    margin-top: 3px;
  }
}

.loading {
  position: relative;
  height: 50px;
  margin-top: 50px;
  padding: 20px;
}

.viewMore {
  display: block;
  text-align: center;
  font-size: 12px;
  color: rgb(35, 66, 112);
  font-weight: 600;
  border: solid 1px rgb(35, 66, 112);
  text-transform: uppercase;
  padding: 5px;
  margin: 10px;

  &:hover {
    border-color: #4183c4;
  }
}
