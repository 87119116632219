.container {
  padding-bottom: 2rem;
}

.containerLoading {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 200px;
  position: relative;
}

.detailLoading {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 250px;
}

.detailWrapper {
  display: flex;
  padding-left: 90px;
  padding-top: 20px;
  position: relative;
}

.graphLoader {
  background: #fff;
  height: 270px;
  margin-top: 25px;
  position: relative;
}
