.amount {
  font-size: 1em;
  font-weight: bold;
}

.prefix {
  font-size: .45em;
  text-transform: uppercase;
}

.currency {
  font-size: .65em;
  text-transform: uppercase;
}