$animation-timeout: 1000ms;

.creditSimulatorContainer {
  flex: 1;
  overflow: hidden;
  width: 933px;
}

:global {
  .stepContent-enter {
    display: inline-block;
    width: 0;
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .stepContent-enter-active {
    display: inline-block;
    transition: all $animation-timeout ease;
    overflow: hidden;
    padding: 20px;
    width: 100%;
  }

  .stepContent-enter-done {
    display: inline-block;
    border-left: 1px solid #323542;
    padding: 20px;
    width: 100%;
  }

  .stepContent-exit {
    display: inline-block;
    transition: all $animation-timeout ease;
    overflow: hidden;
    padding-top: 20px;
    padding-left: 0;
    padding-right: 0;
    width: 0;
  }

  .stepOneContent {
    background: #1d202e;
  }

  .stepTwoContent {
    background: #222637;
  }

  .stepProfitContent {
    background: #282d42;
  }
}