@import '../../styles/variables.scss';

.helpDisplay {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  width: 275px;
}

.helpText {
  color: $font-grey;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;

  &.margin {
    margin-bottom: 100px;
  }
}

.helpLink {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  transition: color 200ms ease;

  &:hover {
    color: #d4d4d4 !important;
    transition: color 200ms ease;
    text-decoration: underline;
  }
}

.btnBack {
  align-items: center;
  background: transparent;
  border: 1px solid #fefefe;
  border-radius: 5px;
  bottom: 0;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-family: $font-family-montserrat;
  font-size: 13px;
  font-weight: 600;
  justify-content: center;
  height: 34px;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  text-transform: uppercase;
  transition: background 200ms ease;

  &:hover {
    background: #787b7d;
    transition: background 200ms ease;
  }
}

.noInfoText {
  margin-top: 15px;
}
