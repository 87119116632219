@import "../../../styles/variables";

.sliderContainer {
  margin-bottom: 17px;
}

.minMaxContainer {
  color: #ffffff;
  display: flex;
  font-size: 13px;
  font-weight: 300;
  justify-content: space-between;
}

.labelContainer {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 15px;
  font-weight: 400;
  justify-content: space-between;
  margin-bottom: 13px;
  text-transform: uppercase;
}

.displayInput {
  background: transparent;
  border: 1px solid #8f97cc;
  border-radius: 3px;
  color: #ffffff;
  font-family: $default-font;
  font-size: 20px;
  font-weight: 600;
  height: 28px;
  line-height: 28px;
  text-align: center;
  width: 230px;
}

.sliderContainerAnimated {
  :global {
    .rc-slider-handle {
      transition: left 1000ms ease;
    }
  }
}