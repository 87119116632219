.spaRightSectionImageHeader {
  float: left;
  left: 0;
  position: absolute;
  top: -140px;
  width: 100%;
  overflow: hidden;

  img {
    filter: grayscale(100%);
  }

  &::after {
    background-image: linear-gradient(to top, #ffffff 10%, transparent);
    background-size: 100% 150px;
    background-repeat: no-repeat;
    background-position: bottom;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}