@import "../../styles/variables";

.actionsContainer {
  display: flex;
  justify-content: flex-start;
}

.actionLink,
.actionButton {
  color: $dark-slate-blue;
  fill: $dark-slate-blue;
  margin-right: 15px;
  transition: all 200ms ease;

  &:hover {
    fill: $dusk-blue;
    transition: all 200ms ease;
  }

  &:disabled {
    color: $disabled;
    cursor: default;
    fill: $disabled;
  }
}

.actionButton {
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
}