@import "../../styles/variables.scss";

.pointsBox {
  display: flex;
}

.box {
  color: $font-grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 70px;
  font-size: 12px;
}

.boxLabel {
  text-transform: uppercase;
  margin-right: 10px;
}

.boxValue {
  color: $primary-color;
  font-weight: 600;
}
