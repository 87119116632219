@import "../../styles/variables";

.inputContainer {
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
}

.input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #a7afbe;
  box-shadow: none;
  color: #4b5e73;
  font-family: $default-font;
  font-size: 12px;
  font-weight: 600;
  height: 22px;
  line-height: 22px;
  margin-left: 20px;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  width: 100%;
}

.searchButton {
  cursor: pointer;
  fill: #36417b;
  position: absolute;
  right: 2px;
  top: 0;
  transition: transform 200ms ease;

  &:hover {
    transform: scale(1.1);
    transition: transform 200ms ease;
  }
}